import { Column } from 'primereact/column'
import { classNames } from 'primereact/utils'
import React from 'react'
import List from '../../classes/List'
import Container from '../../components/layout/Container'
import Page from '../../components/layout/Page'
import DataList from '../../components/utils/DataList'
import { getEmpresaDTO } from '../../dtos/cadastro/EmpresaDTO'
import { Button } from 'primereact/button'
import { formatDateTime } from '../../helpers/formaters'
import ListagemECRVSPService from '../../services/cadastro/ListagemECRVSPService'
import { showErrorMessage, showSuccessMessage } from '../../components/utils/Message'

export default class ListagemECRVSP extends List {
  constructor (props) {
    super(
      props,
      getEmpresaDTO,
      ListagemECRVSPService,
      '',
      'codigo_emp',
      'Pendentes'
    )

    this.state = {
      ...this.state,
      filter: {
        ...this.state.filter,
        somenteVinculado: false
      }
    }

    this.setColumns([
      {
        key: 'codigo_emp',
        component: (
          <Column
            key='codigo_emp'
            headerClassName='codigo_emp'
            header="CÓDIGO"
            field="codigo_emp"
            style={{ width: '10%' }}
            sortable
          />
        )
      },
      {
        key: 'nomfan_emp',
        component: (
          <Column
            key='nomfan_emp'
            headerClassName='nomfan_emp'
            header="EMPRESA"
            field="nomfan_emp"
            style={{ width: '20%' }}
            sortable
          />
        )
      },
      {
        key: 'cnpj_emp',
        component: (
          <Column
            key='cnpj_emp'
            headerClassName='cnpj_emp'
            header="CNPJ"
            field="cnpj_emp"
            style={{ width: '10%' }}
            sortable
          />
        )
      },
      {
        key: 'dahins_emp',
        component: (
          <Column
            key='dahins_emp'
            headerClassName='dahins_emp'
            header="DATA/HORA CADASTRO"
            field="dahins_emp"
            style={{ width: '15%' }}
            sortable
            body={(data) => formatDateTime(data.dahins_emp)}
          />
        )
      },
      {
        key: 'despachante',
        component: (
          <Column
            key='despachante'
            headerClassName='despachante'
            header="DESPACHANTE"
            field="despachante"
            style={{ width: '20%' }}
            sortable
          />
        )
      },
      {
        key: 'acoes',
        component: (
          <Column
            key='acoes'
            headerClassName='acoes'
            header="AÇÕES"
            field=""
            style={{ width: '15%' }}
            body={(data) => (
              <Button
                label={ data.vincecrv_emp ? 'Desvincular' : 'Marcar como vinculado'}
                onClick={ () => this.atualizarVinculoECRVSP(data)}
                className={`p-button${data.vincecrv_emp ? '-danger' : ''}`}
              />
            )}
          />
        )
      }
    ])
  }

  atualizarVinculoECRVSP = async (data) => {
    try {
      const response = await ListagemECRVSPService.definirVinculoECRV(data.vincecrv_emp ? false : true, data.codigo_emp)
      await this.onFilter()
      showSuccessMessage(response)
    } catch (error) {
      showErrorMessage(error.message || 'Erro ao atualizar o vínculo eCRV SP')
    }
  }

  onSelectTab = (option) => {
    let { filter } = this.state
    this.setState({ opcaoSelecionada: option })
    if (option === 'Pendentes') {
      filter.somenteVinculado = false
    } else {
      filter.somenteVinculado = true
    }
    this.setState({ filter }, this.onFilter)
  }

  render () {
    const state = this.state

    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Lojistas pendentes de vinculação no ECRV - SP</h4>
        </div>
        <div className="table-options-container">
          <div className="table-options">
            <div className={classNames({ active: this.state.opcaoSelecionada === 'Pendentes' }, 'filter-option')}
              onClick={() => this.onSelectTab('Pendentes')} >
              <span className="option-label">Pendentes</span>
              <div className="option-quantity">
                {this.state.quantidades?.qtdPendente}
              </div>
            </div>
            <div className={classNames({ active: this.state.opcaoSelecionada === 'Vinculados' }, 'filter-option')}
              onClick={ () => this.onSelectTab('Vinculados')} >
              <span className="option-label">Vinculados</span>
              <div className="option-quantity">
                {this.state.quantidades?.qtdVinculada}
              </div>
            </div>
          </div>
        </div>
        <Container>
          <DataList
            data={state.list}
            selected={state.selected}
            rows={state.rows}
            onSelect={this.onSelect}
            totalRecords={state.totalRecords}
            first={state.first}
            onPage={this.onPageChange}
            page={state.page}
            showFilter
            filterPlaceholder="Procurar empresa"
            filterName="descricaoFiltro"
            responsive
            filterOnChange={this.handleChangeFilter}
            onFilter={this.onFilter}
          >
            {this.columns.map((column) => column.component)}
          </DataList>
        </Container>
      </Page>
    )
  }
}

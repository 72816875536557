export const getUsuarioDTO = (data = {}) => {
  return {
    codigoUsu: data.codigo_usu || null,
    nomeUsu: data.nome_usu || null,
    emailUsu: data.email || null,
    cpfUsu: data.cpf_usu || null,
    telcomUsu: data.telcom_usu || null,
    isconfUsu: data.isconf_usu || null,
    ativoUsu: data.ativo_usu || false,
    usuinsUsu: data.usuins_usu || null,
    codgruUsu: data.codgru_usu || null,
    codempUsu: data.codemp_usu || null
  }
}

export const postUsuarioDTO = (data = {}) => {
  return {
    codigo_usu: data.codigoUsu || null,
    nome_usu: data.nomeUsu || null,
    email_usu: data.emailUsu || null,
    cpf_usu: data.cpfUsu || null,
    telcom_usu: data.telcomUsu || null,
    codemp_usu: data.codempUsu || null,
    isconf_usu: data.isconfUsu || false,
    ativo_usu: data.ativoUsu || false,
    usuins_usu: data.usuinsUsu || null,
    codgru_usu: data.codgruUsu || null
  }
}

import { Column } from 'primereact/column'
import React from 'react'
import { IoWarningOutline } from 'react-icons/io5'
import List from '../../../classes/List'
import DataList from '../../../components/utils/DataList'
import Modal from '../../../components/utils/Modal'
import { getLoteArquivoAtpvDTO } from '../../../dtos/atpv/LoteArquivoAtpvDTO'
import CommonHelper from '../../../helpers/CommonHelper'
import { formatDateTime } from '../../../helpers/formaters'
import LoteAtpvService from '../../../services/atpv/LoteAtpvService'

class LoteAtpveList extends List {
  constructor (props) {
    super(
      props,
      getLoteArquivoAtpvDTO,
      LoteAtpvService
    )

    this.state = {
      ...this.state,
      visibleErroLoteModal: false,
      loteAtpveSelecionado: {}
    }

    //#region Criando colunas

    this.setColumns([
      {
        key: 'codigo',
        component: (
          <Column
            key='codigo'
            style={{ width: '15%' }}
            header="Codigo"
            field="codigo"
            sortable
            headerClassName="codigo"
          />
        )
      },
      {
        key: 'nome_usu',
        component: (
          <Column
            key='nome_usu'
            style={{ width: '15%' }}
            header="Usuário"
            field="nome_usu"
            sortable
            headerClassName="nome_usu"
          />
        )
      },
      {
        key: 'datahorageracao',
        component: (
          <Column
            key='datahorageracao'
            style={{ width: '15%' }}
            header="Data/Hora geração"
            field="datahorageracao"
            body={data => formatDateTime(data.datahorageracao)}
            sortable
            headerClassName="datahorageracao"
          />
        )
      },
      {
        key: 'totalarquivoslote',
        component: (
          <Column
            key='totalarquivoslote'
            style={{ width: '15%' }}
            header="Total arquivos"
            field="totalarquivoslote"
            sortable
            headerClassName="totalarquivoslote"
          />
        )
      },
      {
        key: 'totalarquivosprocessados',
        component: (
          <Column
            key='totalarquivosprocessados'
            style={{ width: '15%' }}
            header="Total arquivos processados"
            field="totalarquivosprocessados"
            sortable
            headerClassName="totalarquivosprocessados"
          />
        )
      },
      {
        key: 'erros',
        component: (
          <Column
            key='erros'
            style={{ width: '15%' }}
            header="Falha"
            field="falha"
            body={data =>
              data.erromensagemusuario
                ? <IoWarningOutline
                  className="info-selection warning"
                  onClick={() => this.setState({ loteAtpveSelecionado: data, visibleErroLoteModal: true })}
                />
                : ''
            }
            sortable
            headerClassName="totalarquivosprocessados"
          />
        )
      },
      {
        key: 'pronto',
        component: (
          <Column
            key='pronto'
            style={{ width: '15%' }}
            header="Baixar"
            field="pronto"
            body={data =>
              data.pronto
                ? <i className="pi pi-download info-selection" onClick={() => this.handleDownloadLote(data)}></i>
                : <i className="pi pi-spin pi-spinner info-selection"/>
            }
            sortable
            headerClassName="pronto"
          />
        )
      }
    ])

    //#endregion
  }

  async handleDownloadLote (lote) {
    try {
      let zipLoteAtpvBase64 = await LoteAtpvService.downloadLoteApve(lote.arquivo)
      CommonHelper.downloadFile('data:application/zip;base64,', zipLoteAtpvBase64, `Lote-Atpv-${lote.codigo}.zip`)
    } catch (error) {}
  }

  async componentDidMount () {
    await this.getAll()
    this.addEventListeners()
    this.resetFiltro()
    this.props.setQuantidadeLotesAtpv(this.state.totalRecords)
  }

  async componentDidUpdate (prevProps) {
    if (!prevProps.atualizarLoteAtpvList && this.props.atualizarLoteAtpvList) {
      await this.onFilter()
      this.props.setQuantidadeLotesAtpv(this.state.totalRecords)
      this.props.setAtualizarLoteAtpvList(false)
    }
  }

  render () {
    const { totalRecords, rows, first, page, list } = this.state

    return (
      <>
        <DataList
          className="importar-xml-datalist"
          data={list}
          rows={rows}
          totalRecords={totalRecords}
          first={first}
          onPage={this.onPageChange}
          page={page}
          responsive
        >
          {this.columns.map((column) => column.component)}
        </DataList>
        <Modal
          header="Erro ao processar o lote ATPV-e"
          visible={this.state.visibleErroLoteModal}
          onHide={() => this.setState({ visibleErroLoteModal: false })}
          width={45}
        >
          {this.state.loteAtpveSelecionado.erromensagemusuario}
        </Modal>
      </>
    )
  }
}

export default LoteAtpveList

import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { classNames } from 'primereact/utils'
import React from 'react'
import List from '../../../classes/List'
import DateInput from '../../../components/inputs/DateInput'
import Container from '../../../components/layout/Container'
import Page from '../../../components/layout/Page'
import DataList from '../../../components/utils/DataList'
import { showErrorMessage, showSuccessMessage } from '../../../components/utils/Message'
import ResponsiveColumn from '../../../components/utils/ResponsiveColumn'
import { getVeiculoSaidaDTO } from '../../../dtos/renave/VeiculoSaidaDTO'
import CommonHelper from '../../../helpers/CommonHelper'
import { formatDate } from '../../../helpers/formaters'
import LoteAtpvService from '../../../services/atpv/LoteAtpvService'
import ImprimirAtpveTermoSaidaModal from './ImprimirAtpveTermoSaidaModal'
import LoteAtpveList from './LoteAtpveList'
import { stringify } from 'query-string'
import ImpressaoATPVeService from '../../../services/zeroKm/veiculos/ImpressaoATPVeService'

class ImpressaoAtpve extends List {
  constructor (props) {
    super(
      props,
      getVeiculoSaidaDTO,
      ImpressaoATPVeService,
      '',
      '',
      'finalizados'
    )

    this.state = {
      ...this.state,
      selectedRows: [],
      veiculosFinalizados: [],
      visibleImprimirAtpveTermoSaidaModal: false,
      atualizarLoteAtpvList: false,
      quantidadeLotesAtpv: null,
      chassiParaImpressao: '',
      veiculoAcabado: true,
      idEstoque: null
    }

    this.setColumns([
      {
        key: 'selectionColumn',
        component: (
          <Column
            key='selectionColumn'
            header="Sel"
            style={{ width: '5%' }}
            selectionMode="multiple"
          />
        )
      },
      {
        key: 'chassi_vei',
        component: (
          <Column
            key='chassi_vei'
            style={{ width: '10%' }}
            header="Chassi"
            field="chassi_vei"
            sortable
            headerClassName="chassi_vei"
          />
        )
      },
      {
        key: 'descri_vei',
        component: (
          <Column
            key='descri_vei'
            style={{ width: '20%' }}
            header="Marca/Modelo"
            field="descri_vei"
            sortable
            headerClassName="descri_vei"
          />
        )
      },
      {
        key: 'anofab_vei',
        component: (
          <Column
            key='anofab_vei'
            style={{ width: '10%' }}
            header="Fab/Mod"
            field="anofab_vei"
            body={(data) => data.anofab_vei ? (data.anofab_vei + '/' + data.anomod_vei) : ''}
            sortable
            headerClassName="anofab_vei"
          />
        )
      },
      {
        key: 'cor_vei',
        component: (
          <Column
            key='cor_vei'
            style={{ width: '15%' }}
            header="Cor"
            field="cor_vei"
            sortable
            headerClassName="cor_vei"
          />
        )
      },
      {
        key: 'nomraz_pes',
        component: (
          <Column
            key='nomraz_pes'
            style={{ width: '15%' }}
            header="Cliente"
            field="nomraz_pes"
            sortable
            headerClassName="nomraz_pes"
          />
        )
      },
      {
        key: 'datsai_vei',
        component: (
          <Column
            key='datsai_vei'
            style={{ width: '10%' }}
            header="Data venda"
            field="datsai_vei"
            body={(data) => data.datsai_vei ? formatDate(data.datsai_vei) : ''}
            sortable
            headerClassName="datsai_vei"
          />
        )
      },
      {
        key: 'acabado_vei',
        component: (
          <Column
            key='acabado_vei'
            header="Inacabado?"
            field="acabado_vei"
            body={data => <ResponsiveColumn
              column="acabado_vei"
              value={data.acabado_vei === 0 ? 'Inacabado' : 'Acabado'}
              className={data.acabado_vei === 0 ? 'colored-label orange' : 'colored-label blue'}
            />}
            style={{ width: '7%', maxWidth: '7%' }}
            sortable
            headerClassName="acabado_vei"
          />
        )
      },
      {
        key: 'Imprimir',
        component: (
          <Column
            key='imprimir'
            header="Imprimir"
            style={{ width: '13%' }}
            headerClassName="Imprimir"
            body={(data) =>
              <Button
                icon="pi pi-print"
                onClick={() => this.setState({ visibleImprimirAtpveTermoSaidaModal: true, chassiParaImpressao: data.chassi_vei, veiculoAcabado: data.acabado_vei, idEstoque: data.idest_vei })}
              />
            }
          />
        )
      }
    ])
  }

  isMontadora () {
    return window.location.pathname.includes('montadora')
  }

  async atualizarQuantidadeLotesAtpv () {
    const quantidadeLotesAtpv = await LoteAtpvService.getQuantidadeLotesAtpvNovo()
    this.setState({ quantidadeLotesAtpv })
  }

  async componentDidMount () {
    this.addEventListeners()
    const filter = {}
    filter.descricaoFiltro = undefined
    this.setState({ filter }, this.onFilter)
    const veiculosFinalizados = await ImpressaoATPVeService.getTodos()
    await this.atualizarQuantidadeLotesAtpv()
    this.setState({ veiculosFinalizados })
  }

  async baixarSelecionados () {
    try {
      if (this.state.selectedRows.length === 0) return

      await LoteAtpvService.processarLoteAtpvNovo(this.state.selectedRows.map(row => row.chassi_vei), this.isMontadora())
      await this.atualizarQuantidadeLotesAtpv()
      showSuccessMessage('Lote ATPV-e criado com sucesso!')
      this.setState({ selectedRows: [] })
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro ao tentar criar o lote ATPV-e!')
    }
  }

  isAllSelected () {
    return this.state.selectedRows.length === this.state.veiculosFinalizados.length
  }

  selecionarTodos = () => {
    let selectedRows = []

    if (!this.isAllSelected()) {
      selectedRows = this.state.veiculosFinalizados
    }

    this.setState({ selectedRows })
  }

  onFilterClick = async () => {
    const filter = this.state.filter
    filter.dataSaidaInicial = CommonHelper.dateToAmerican(this.state.dataSaidaInicial)
    filter.dataSaidaFinal = CommonHelper.dateToAmerican(this.state.dataSaidaFinal)
    this.setState({ filter })
    this.onFilter()

    let veiculosFinalizados = await ImpressaoATPVeService.filterQuery(stringify(this.state.filter, { skipNull: true, skipEmptyString: true, encode: false }))

    this.setState({ veiculosFinalizados })
  }

  expandedFiltersTemplate = () => (
    <>
      <div className="field col-12 md:col-4">
        <label className="label">Data de saída do veículo</label>
        <div className="formgrid grid">
          <div className="col-6 md:col-6">
            <DateInput
              name="dataSaidaInicial"
              placeholder="Data Inicial"
              className="inputfield w-full"
              value={this.state.dataSaidaInicial}
              onChange={(e) => this.setState({ dataSaidaInicial: e.target.value })} />
          </div>
          <div className="col-6 md:col-6">
            <DateInput
              name="dataSaidaFinal"
              placeholder="Data Final"
              className="inputfield w-full"
              value={this.state.dataSaidaFinal}
              onChange={(e) => this.setState({ dataSaidaFinal: e.target.value })} />
          </div>
        </div>
      </div>
      <div className="col-12 flex justify-content-end mb-4">
        <Button label="Buscar" icon="pi pi-search" onClick={this.onFilterClick} />
      </div>
    </>
  )

  render () {
    const state = this.state

    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>
            ATPV-e / Termo de saída
          </h4>
        </div>
        <div className="table-options-container">
          <div className="table-options">
            <div className={classNames({ active: state.opcaoSelecionada === 'finalizados' }, 'filter-option')}
              onClick={() => this.setState({ opcaoSelecionada: 'finalizados' })} >
              <span className="option-label">Finalizados</span>
              <div className="option-quantity">
                {state.totalRecords}
              </div>
            </div>
            <div className={classNames({ active: state.opcaoSelecionada === 'lotesAtpve' }, 'filter-option')}
              onClick={() => this.setState({ opcaoSelecionada: 'lotesAtpve' })} >
              <span className="option-label">Lotes ATPV-e / Termo de saída</span>
              <div className="option-quantity">
                {state.quantidadeLotesAtpv}
              </div>
            </div>
          </div>
          <div className="table-preferences">
            <Button
              icon="pi pi-refresh"
              onClick={() => this.setState({ atualizarLoteAtpvList: true })}
              className="mr-3 mb-1"
              disabled={state.opcaoSelecionada !== 'lotesAtpve'}
            />
            <Button
              label="Selecionar Todos"
              onClick={() => this.selecionarTodos()}
              className="mb-1 mr-3"
              disabled={state.opcaoSelecionada === 'lotesAtpve'}
            />
            <Button
              label="Baixar Selecionados"
              onClick={async () => await this.baixarSelecionados()}
              className="mb-1 mr-3"
              disabled={state.selectedRows.length === 0 || state.opcaoSelecionada === 'lotesAtpve'}
            />
          </div>
        </div>
        <Container>
          {state.opcaoSelecionada === 'finalizados' && (
            <DataList
              className="without-header-checkbox"
              dataKey="codigo_vei"
              data={state.data}
              rows={state.rows}
              totalRecords={state.totalRecords}
              first={state.first}
              onPage={this.onPageChange}
              page={state.page}
              responsive
              selected={state.selectedRows}
              onSelectionChange={e => {
                if (!e.originalEvent.target.className.includes('print')) {
                  this.setState({ selectedRows: e.value })
                }
              }}
              showFilter
              filterPlaceholder="Procurar veículo"
              filterName="descricaoFiltro"
              filterOnChange={this.handleChangeFilter}
              onFilter={this.onFilter}
              expandedFiltersTemplate={this.expandedFiltersTemplate}
            >
              {this.columns.map((column) => column.component)}
            </DataList>
          )}
          {state.opcaoSelecionada === 'lotesAtpve' && (
            <>
              <LoteAtpveList
                atualizarLoteAtpvList={state.atualizarLoteAtpvList}
                setAtualizarLoteAtpvList={(newValue) => this.setState({ atualizarLoteAtpvList: newValue })}
                setQuantidadeLotesAtpv={(quantidade) => this.setState({ quantidadeLotesAtpv: quantidade })}
              />
            </>
          )}
        </Container>
        <ImprimirAtpveTermoSaidaModal
          visible={state.visibleImprimirAtpveTermoSaidaModal}
          onHide={() => this.setState({ visibleImprimirAtpveTermoSaidaModal: false })}
          chassi={state.chassiParaImpressao}
          veiculoAcabado={state.veiculoAcabado}
          idEstoque={state.idEstoque}
        />
      </Page>
    )
  }
}

export default ImpressaoAtpve

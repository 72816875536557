import { Column } from 'primereact/column'
import { classNames } from 'primereact/utils'
import React from 'react'
import { IoWarningOutline } from 'react-icons/io5'
import List from '../../../classes/List'
import Container from '../../../components/layout/Container'
import Page from '../../../components/layout/Page'
import DataList from '../../../components/utils/DataList'
import { showErrorMessage, showSuccessMessage } from '../../../components/utils/Message'
import Modal from '../../../components/utils/Modal'
import { getXmlImportDTO } from '../../../dtos/xml/XmlImportDTO'
import CommonHelper from '../../../helpers/CommonHelper'
import XmlImportService from '../../../services/zeroKm/xml/XmlImportService'

class XmlImportList extends List {
  constructor (props) {
    super(
      props,
      getXmlImportDTO,
      XmlImportService,
      '',
      ''
    )

    this.state = {
      ...this.state,
      lote: props.loteXml,
      visibleInfoModal: false
    }

    this.setColumns([
      {
        key: 'codigo',
        component: (
          <Column
            header="Codigo"
            field="codigo"
            style={{ width: '5%', maxWidth: '5%' }}
            sortable
            sortField="null1"
            headerClassName="codigo"
          />
        )
      },
      {
        key: 'numeronf',
        component: (
          <Column
            header="NF"
            field="numeronf"
            style={{ width: '5%', maxWidth: '5%' }}
            sortable
            sortField="null2"
            headerClassName="numeronf"
          />
        )
      },
      {
        key: 'chassi',
        component: (
          <Column
            header="Chassi"
            field="chassi"
            style={{ width: '15%', maxWidth: '15%' }}
            sortable
            sortField="null3"
            headerClassName="chassi"
          />
        )
      },
      {
        key: 'cnpjemitente',
        component: (
          <Column
            header="CNPJ emitente"
            field="cnpjemitente"
            style={{ width: '15%', maxWidth: '15%' }}
            sortable
            sortField="null4"
            headerClassName="cnpjemitente"
          />
        )
      },
      {
        key: 'nome',
        component: (
          <Column
            header="Destinatário"
            field="nome"
            style={{ width: '15%', maxWidth: '15%' }}
            sortable
            sortField="null5"
            headerClassName="nome"
          />
        )
      },
      {
        key: 'cpfcnpj',
        component: (
          <Column
            header="CPF/CNPJ destinatário"
            field="cpfcnpj"
            style={{ width: '15%', maxWidth: '15%' }}
            sortable
            sortField="null6"
            headerClassName="cpfcnpj"
          />
        )
      },
      {
        key: 'uf',
        component: (
          <Column
            header="UF"
            field="uf"
            style={{ width: '5%', maxWidth: '5%' }}
            sortable
            sortField="null7"
            headerClassName="uf"
          />
        )
      },
      {
        key: 'tiponf',
        component: (
          <Column
            header="Tipo"
            field="tiponf"
            body={data => {
              if (data.tiponf === 'E')
                return <span className="colored-label blue fixed-length">Entrada</span>

              if (data.tiponf === 'S')
                return <span className="colored-label light-green fixed-length">Saída</span>

              if (data.tiponf === 'T')
                return <span className="colored-label yellow fixed-length">Transferência</span>
            }}
            style={{ width: '10%', maxWidth: '10%' }}
            sortable
            sortField="null8"
            headerClassName="tiponf"
          />
        )
      },
      {
        component: (
          <Column
            header="Status"
            field="erroxml"
            body={data =>
              data.erroxml
                ? <IoWarningOutline
                  className="info-selection"
                  onClick={() => this.setState({ errorMessage: data.erroxml, visibleInfoModal: true })}
                />
                : <i className="pi pi-check info-selection  cursor-auto"></i>
            }
            style={{ width: '5%', maxWidth: '5%' }}
          />
        )
      },
      {
        component: (
          <Column
            header="Download xml"
            body={data =>
              <i className="pi pi-download info-selection" onClick={() => this.downloadXml(data)}></i>
            }
            style={{ width: '5%', maxWidth: '5%' }}
          />
        )
      }
    ])
  }

  async downloadXml (data) {
    try {
      const arquivoXmlBase64 = await XmlImportService.downloadXml(data.arquivo)
      CommonHelper.downloadFile('data:application/xml;base64,', arquivoXmlBase64, `XML-${data.codigo}.xml`)
      showSuccessMessage('Arquivo XML baixado com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar fazer download do arquivo XML')
    }
  }

  async getAllByLote (codigoLote) {
    let response = await this.modelService.getAll(this.state.page, this.state.rows, codigoLote)
    this.setRetorno(response)
  }

  filterByLote = async () => {
    try {
      let response = await this.modelService.filter(this.toFilter(), this.state.page, this.state.rows, this.state.lote.idLote)
      this.setRetorno(response)
    } catch (error) {
      showErrorMessage(error.message || 'Houve um erro ao filtrar os registros!')
    }
  }

  componentDidMount () {
    super.onFilter = this.filterByLote
    this.getAllByLote(this.state.lote.idLote)
    this.addEventListeners()
    this.resetFiltro()
  }

  render () {
    const { rows, totalRecords, first, page, list } = this.state

    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>
            Lista dos Xmls do lote número: {this.state.lote.idLote}
          </h4>
        </div>
        <div className="table-options-container">
          <div className="table-options">
            <div className={classNames({ active: this.state.opcaoSelecionada === 't' }, 'filter-option')}>
              <span className="option-label">Todos</span>
              <div className="option-quantity">
                {totalRecords}
              </div>
            </div>
          </div>
          <div className="table-preferences">
          </div>
        </div>
        <Container>
          <DataList
            data={list}
            rows={rows}
            totalRecords={totalRecords}
            first={first}
            onPage={this.onPageChange}
            page={page}
            responsive
            showFilter
            filterPlaceholder="Procurar xmls importados"
            filterName="descricaoFiltro"
            filterOnChange={this.handleChangeFilter}
            onFilter={this.onFilter}
          >
            {this.columns.map((column) => column.component)}
          </DataList>
        </Container>
        <Modal
          header="Erro ao tentar processar o XML."
          visible={this.state.visibleInfoModal}
          onHide={() => this.setState({ visibleInfoModal: false })}
          width={45}
        >
          {this.state.errorMessage}
        </Modal>
      </Page>
    )
  }
}

export default XmlImportList

import ServiceBase from '../../base/ServiceBase'

export default class XmlImportService {
  async getAll (page, perPage, codigoLote) {
    return await ServiceBase.getBase(`xml/getXmlImportsByLote?codigoLote=${codigoLote}&page=${page}&perPage=${perPage}`)
  }

  async get (id) {
    return await ServiceBase.getBase(`xml/getLote/${id}`)
  }

  async getXmlItem (id) {
    return await ServiceBase.getBase(`xml/getXmlItem/${id}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`xml/getXmlImportsByLote?${query}&page=${page}&perPage=${perPage}`)
  }

  static async downloadXml (nomeArquivoBucket) {
    return await ServiceBase.postBase('xml/downloadXml', { nomeArquivoBucket })
  }

  static async getMostRecentXmlEntradaByChassi (chassi) {
    return await ServiceBase.getBase(`xml/getMostRecentXmlEntradaByChassi/${chassi}`)
  }
}

import { Button } from 'primereact/button'
import React, { useState } from 'react'
import TextInput from '../../components/inputs/TextInput'
import { showErrorMessage, showSuccessMessage } from '../../components/utils/Message'
import Modal from '../../components/utils/Modal'
import { formatCurrencyToDecimal, formatToCurrency } from '../../helpers/formaters'
import CreditosDetranService from '../../services/taxaDetran/CreditosDetranService'

//
// Este arquivo deve ser deletado após homologação do pix
//
const InserirCreditoDetranModal = ({ visible, onHide }) => {
  const [valorCredito, setvalorCredito] = useState(0)
  const [senha, setSenha] = useState('')

  const handleInserirCredito = async () => {
    try {
      if (valorCredito > 0) {
        await CreditosDetranService.adicionarCreditos(valorCredito)
        setSenha('')
        setvalorCredito(0)
        showSuccessMessage('Crédito adicionado com sucesso')
        onHide()
      } else {
        showErrorMessage('Informe um valor de crédito!')
      }
    } catch (error) {
      showErrorMessage(error.message || 'Houve um erro ao transferir o veículo!')
    }
  }

  return (
    <Modal
      header={'Adicionar crédito para taxa do DETRAN'}
      width={45}
      onHide={onHide}
      visible={visible}
      footer={
        <Button
          label="Confirmar"
          onClick={() => handleInserirCredito()}
          disabled={senha !== 'taxa@renaveauto'}
        />
      }
    >
      <div className="formgrid grid">
        <div className="field col-8">
          <TextInput
            label="Valor"
            name="valor_pix"
            placeholder="Valor a ser creditado"
            className="inputfield w-full"
            value={formatToCurrency(valorCredito)}
            onChange={(e) => setvalorCredito(formatCurrencyToDecimal(e.target.value))}
            // disabled={true}
          />
        </div>
        <div className="field col-4">
          <TextInput
            type="password"
            label="Senha"
            placeholder="Senha"
            className="inputfield w-full"
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
            // disabled={true}
          />
        </div>
      </div>
    </Modal>
  )
}

export default InserirCreditoDetranModal

import React from 'react'
import Container from '../../../components/layout/Container'
import Page from '../../../components/layout/Page'
import { Column } from 'primereact/column'
import List from '../../../classes/List'
import DataList from '../../../components/utils/DataList'
import { classNames } from 'primereact/utils'
import { Button } from 'primereact/button'
import Confirm from '../../../components/utils/Confirm'
import VeiculoFinalizadoService from '../../../services/zeroKm/veiculos/VeiculoFinalizadoService'
import { getVeiculoSaidaDTO } from '../../../dtos/renave/VeiculoSaidaDTO'
import { formatDate } from '../../../helpers/formaters'
import DateInput from '../../../components/inputs/DateInput'
import CommonHelper from '../../../helpers/CommonHelper'
import { showErrorMessage, showSuccessMessage } from '../../../components/utils/Message'
import SaidaZeroKmService from '../../../services/zeroKm/veiculos/SaidaZeroKmService'
import ResponsiveColumn from '../../../components/utils/ResponsiveColumn'

export class CancelarSaida extends List {
  constructor (props) {
    super(
      props,
      getVeiculoSaidaDTO,
      VeiculoFinalizadoService,
      '',
      '',
      'finalizados'
    )

    this.state = {
      ...this.state,
      veiculoParaCancelar: {}
    }

    //#region Criando colunas.

    this.setColumns([
      {
        key: 'chassi_vei',
        component: (
          <Column
            key='chassi_vei'
            style={{ width: '10%' }}
            header="Chassi"
            field="chassi_vei"
            sortable
            headerClassName="chassi_vei"
          />
        )
      },
      {
        key: 'descri_vei',
        component: (
          <Column
            key='descri_vei'
            style={{ width: '25%' }}
            header="Marca/Modelo"
            field="descri_vei"
            sortable
            headerClassName="descri_vei"
          />
        )
      },
      {
        key: 'acabado_vei',
        component: (
          <Column
            key='acabado_vei'
            style={{ width: '10%' }}
            header="Inacabado?"
            body={data => <ResponsiveColumn
              column="acabado_vei"
              value={data.acabado_vei === 0 ? 'Inacabado' : 'Acabado'}
              className={data.acabado_vei === 0 ? 'colored-label orange' : 'colored-label blue'}
            />}
            field="acabado_vei"
            sortable
            headerClassName="acabado_vei"
          />
        )
      },
      {
        key: 'anofab_vei',
        component: (
          <Column
            key='anofab_vei'
            style={{ width: '10%' }}
            header="Fab/Mod"
            field="anofab_vei"
            body={(data) => data.anofab_vei ? (data.anofab_vei + '/' + data.anomod_vei) : ''}
            sortable
            headerClassName="anofab_vei"
          />
        )
      },
      {
        key: 'cor_vei',
        component: (
          <Column
            key='cor_vei'
            style={{ width: '15%' }}
            header="Cor"
            field="cor_vei"
            sortable
            headerClassName="cor_vei"
          />
        )
      },
      {
        key: 'nomraz_pes',
        component: (
          <Column
            key='nomraz_pes'
            style={{ width: '20%' }}
            header="Cliente"
            field="nomraz_pes"
            sortable
            headerClassName="nomraz_pes"
          />
        )
      },
      {
        key: 'datsai_vei',
        component: (
          <Column
            key='datsai_vei'
            style={{ width: '10%' }}
            header="Data venda"
            field="datsai_vei"
            body={(data) => data.datsai_vei ? formatDate(data.datsai_vei) : ''}
            sortable
            headerClassName="datsai_vei"
          />
        )
      }
    ])

    //#endregion
  }

  handleCancelarSaida = async () => {
    try {
      await SaidaZeroKmService.cancelarSaidaZeroKm({
        chassi: this.state.veiculoParaCancelar.chassi_vei,
        idEstoque: this.state.veiculoParaCancelar.idest_vei
      })

      this.setState({ visibleConfirm: false })

      await this.getAll()

      showSuccessMessage('Veículo cancelado com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Erro ao cancelar saída!')
    }
  }

  onFilterClick = () => {
    const filter = this.state.filter
    filter.dataSaidaInicial = CommonHelper.dateToAmerican(this.state.dataSaidaInicial)
    filter.dataSaidaFinal = CommonHelper.dateToAmerican(this.state.dataSaidaFinal)
    this.setState({ filter })
    this.onFilter()
  }

  expandedFiltersTemplate = () => (
    <>
      <div className="field col-12 md:col-4">
        <label className="label">Data de saída do veículo</label>
        <div className="formgrid grid">
          <div className="col-6 md:col-6">
            <DateInput
              name="dataSaidaInicial"
              placeholder="Data Inicial"
              className="inputfield w-full"
              value={this.state.dataSaidaInicial}
              onChange={(e) => this.setState({ dataSaidaInicial: e.target.value })} />
          </div>
          <div className="col-6 md:col-6">
            <DateInput
              name="dataSaidaFinal"
              placeholder="Data Final"
              className="inputfield w-full"
              value={this.state.dataSaidaFinal}
              onChange={(e) => this.setState({ dataSaidaFinal: e.target.value })} />
          </div>
        </div>
      </div>
      <div className="col-12 flex justify-content-end mb-4">
        <Button label="Buscar" icon="pi pi-search" onClick={this.onFilterClick} />
      </div>
    </>
  )

  render () {
    const { rows, totalRecords, first, page, list, selected } = this.state

    const rowExpansionTemplate = (data) => {
      return (
        <div className="expansion row-expansion grid justify-content-end">
          <Button
            label="Cancelar saída"
            className="expansion-button p-button-danger"
            onClick={() => this.setState({ visibleConfirm: true, veiculoParaCancelar: data })}
          />
        </div>
      )
    }

    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Cancelar saída</h4>
        </div>
        <div className="table-options-container">
          <div className="table-options">
            <div className={classNames({ active: this.state.opcaoSelecionada === 'finalizados' }, 'filter-option')}>
              <span className="option-label">Finalizados</span>
              <div className="option-quantity">
                {totalRecords}
              </div>
            </div>
          </div>
        </div>
        <Container>
          <DataList
            data={list}
            selected={selected}
            rows={rows}
            onSelect={this.onSelect}
            totalRecords={totalRecords}
            first={first}
            onPage={this.onPageChange}
            page={page}
            responsive
            showFilter
            filterPlaceholder="Procurar veículos"
            filterName="descricaoFiltro"
            filterOnChange={this.handleChangeFilter}
            onFilter={this.onFilter}
            onRowClick={(e) => this.handleRowExpansion(e.data)}
            expandedRows={this.state.expandedRows}
            rowExpansionTemplate={rowExpansionTemplate}
            expandedFiltersTemplate={this.expandedFiltersTemplate}
          >
            {this.columns.map((column) => column.component)}
          </DataList>
        </Container>
        <Confirm
          title="Cancelar saída"
          visible={this.state.visibleConfirm}
          onCancel={() => this.setState({ visibleConfirm: false })}
          onConfirm={() => this.handleCancelarSaida()}
          description="Confirma cancelar a saída?"
          cancelLabel="Não"
        />
      </Page>
    )
  }
}

import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { classNames } from 'primereact/utils'
import React from 'react'
import TextInput from '../../../components/inputs/TextInput'
import FieldErrorMessage from '../../../components/utils/FieldErrorMessage'
import { showErrorMessage, showSuccessMessage } from '../../../components/utils/Message'
import RequiredLabel from '../../../components/utils/RequiredLabel'
import { useValidateInput } from '../../../helpers/useValidateInput'
import UsuarioService from '../../../services/cadastro/UsuarioService'

const DadosPrincipaisUsu = ({ form, edicaoHabilitada }) => {
  const { isFormFieldValid, getFormErrorMessage } = useValidateInput(form)

  const setFieldTouched = (e) => form.setFieldTouched(e.target.name)

  return (
    <div className="formgrid grid">
      <div className="field col-12 md:col-9">
        <RequiredLabel label="Nome/Razão Social" valid={isFormFieldValid('nomeUsu')}/>
        <TextInput
          value={form.values.nomeUsu}
          name="nomeUsu"
          onChange={form.handleChange}
          className={classNames({ 'p-invalid': isFormFieldValid('nomeUsu') }, 'inputfield w-full')}
          onBlur={setFieldTouched}
          disabled={!edicaoHabilitada}
          placeholder="Nome/Razão Social"
        />
        <FieldErrorMessage message={getFormErrorMessage('nomeUsu')}/>
      </div>
      <div className="field col-3">
        <RequiredLabel label="CPF" valid={isFormFieldValid('cpfUsu')}/>
        <TextInput
          name="cpfUsu"
          placeholder="CPF"
          value={form.values.cpfUsu}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          className={classNames({ 'p-invalid': isFormFieldValid('cpfUsu') }, 'inputfield w-full')}
          mask="999.999.999-99"
          onBlur={setFieldTouched}
        />
        <FieldErrorMessage message={getFormErrorMessage('cpfUsu')}/>
      </div>
      <div className="field col-12 md:col-9">
        <RequiredLabel label="Email" valid={isFormFieldValid('emailUsu')}/>
        <TextInput
          value={form.values.emailUsu}
          name="emailUsu"
          onChange={form.handleChange}
          className={classNames({ 'p-invalid': isFormFieldValid('emailUsu') }, 'inputfield w-full')}
          onBlur={setFieldTouched}
          disabled={!edicaoHabilitada}
          placeholder="Email"
        />
        <FieldErrorMessage message={getFormErrorMessage('emailUsu')}/>
      </div>
      <div className="field col-12 md:col-3">
        <RequiredLabel label="Telefone" valid={isFormFieldValid('telcomUsu')}/>
        <TextInput
          placeholder="Telefone"
          name="telcomUsu"
          value={form.values.telcomUsu}
          onChange={form.handleChange}
          mask="(99) 99999-999?9"
          disabled={!edicaoHabilitada}
          onBlur={setFieldTouched}
          className={classNames({ 'p-invalid': isFormFieldValid('telcomUsu') }, 'inputfield w-full')}
        />
        <FieldErrorMessage message={getFormErrorMessage('telcomUsu')}/>
      </div>
      <div className="field checkbox-container col-1">
        <Checkbox
          name="ativoUsu"
          value={form.values.ativoUsu}
          onChange={form.handleChange}
          checked={form.values.ativoUsu}
          disabled={!edicaoHabilitada}
        />
        <label className="p-checkbox-label mb-0 pl-2">Ativo</label>
      </div>
      {form.values.codigoUsu && !form.values.isconfUsu && (
        <div className="field checkbox-container col-11">
          <Button
            label="Enviar E-mail"
            type="button"
            onClick={async () => {
              try {
                await UsuarioService.enviarEmailConfirmacao(form.values.emailUsu)
                showSuccessMessage('E-mail enviado com sucesso!')
              } catch (error) {
                showErrorMessage(error.message || 'Erro ao enviar e-mail!')
              }
            }}
          />
        </div>
      )}
    </div>
  )
}

export default DadosPrincipaisUsu

import React, { Component } from 'react'
import { Toast } from 'primereact/toast'

let showMessageFn

class Message extends Component {
  render () {
    return (
      <Toast ref={(el) => this.toast = el} />
    )
  }

  componentDidMount () {
    showMessageFn = this.showMessage
  }

    addToast = (toastMessage) => {
      let time = 100

      if (!toastMessage.life) {
        if (toastMessage.length) {
          toastMessage.forEach((message) => {
            let newLife = message.detail.length * time

            if (newLife) {
              message.life = newLife
            }
          })
        } else {
          let newLife = toastMessage.detail.length * time

          if (newLife) {
            toastMessage.life = newLife
          }
        }
      }

      this.toast.show(toastMessage)
    };

    showMessage = (toastMessage) => {
      this.addToast(toastMessage)
    };
}

/**
 * Exibe uma ou mais mensagens na página
 * @param {ToastMessage | ToastMessage[]} toastMessage
 */
export function showMessage (toastMessage) {
  showMessageFn(toastMessage)
}

export const showErrorMessage = (message) => {
  showMessage({ severity: 'error', detail: message })
}

export const showSuccessMessage = (message) => {
  showMessage({ severity: 'success', detail: message })
}

export const showWarnMessage = (message) => {
  showMessage({ severity: 'warn', detail: message })
}

export default Message

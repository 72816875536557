import { Button } from 'primereact/button'
import React, { useState, useEffect } from 'react'
import TextInput from '../../components/inputs/TextInput'
import { showErrorMessage, showSuccessMessage } from '../../components/utils/Message'
import Modal from '../../components/utils/Modal'
import { formatCurrencyToDecimal, formatToCurrency } from '../../helpers/formaters'
import CreditosDetranService from '../../services/taxaDetran/CreditosDetranService'
import { Divider } from 'primereact/divider'
import TextAreaInput from '../../components/inputs/TextAreaInput'
import { FaPix } from 'react-icons/fa6'
import CommonHelper from '../../helpers/CommonHelper'

const InserirPixCreditoDetranModal = ({ visible, onHide }) => {
  const [valorCredito, setValorCredito] = useState(0)
  const [exibirDadosPixCobranca, setExibirDadosPixCobranca] = useState(false)
  const [pixCopiaCola, setPixCopiaCola] = useState('')
  const [qrCodePix, setQrCodePix] = useState('')

  useEffect(() => {
    if (!visible) {
      // Limpa os dados quando o modal fecha
      setValorCredito(0)
      setPixCopiaCola('')
      setQrCodePix('')
      setExibirDadosPixCobranca(false)
    }
  }, [visible])

  const handleGerarPixCobranca = async () => {
    try {
      if (valorCredito > 0) {
        setExibirDadosPixCobranca(false)
        const responsePix = await CreditosDetranService.gerarPixCobranca(valorCredito)
        if (responsePix) {
          setPixCopiaCola(responsePix.data.pixCopiaECola)
          setQrCodePix(responsePix.data.qrCodeBase64)
          showSuccessMessage('qrCode do PIX gerado com sucesso')
          setExibirDadosPixCobranca(true)
        }
      } else {
        showErrorMessage('Informe um valor de crédito!')
      }
    } catch (error) {
      showErrorMessage(error.message || 'Houve um erro ao gerar o PIX!')
    }
  }

  return (
    <Modal
      header={'Adicionar crédito para taxa do DETRAN'}
      width={45}
      onHide={onHide}
      visible={visible}
      footer={
        <Button
          label="Fechar"
          onClick={() => onHide()}
          className='p-button-danger'
          icon="pi pi-times"
        />
      }
    >
      <div className="formgrid grid">
        <div className="flex justify-content-center w-full">
          <div className="field flex flex-column align-items-center gap-2">
            <TextInput
              label="Valor"
              placeholder="Valor a ser creditado"
              className="inputfield w-full"
              value={formatToCurrency(valorCredito)}
              onChange={(e) => setValorCredito(formatCurrencyToDecimal(e.target.value))}
            />
            <Button
              label="Gerar PIX"
              onClick={() => handleGerarPixCobranca()}
              disabled={valorCredito <= 0}
              className='w-full mt-3'
              icon={<FaPix />}
            />
          </div>
        </div>

        {exibirDadosPixCobranca && (
          <div className="w-full">
            <Divider className="alert-divider" />
            <div className="flex justify-content-around align-items-center">
              <div>
                <img src={`data:image/png;base64,${qrCodePix}`} alt="QR Code" />
              </div>
              <div>
                <TextAreaInput
                  value={pixCopiaCola}
                  className="w-full textArea carentDisabled"
                  disabled={true}
                  rows={1}
                  autoResize={false}
                />
                <Button
                  icon="pi pi-copy"
                  type="button"
                  label="Copiar PIX"
                  className="edit-button ml-2 mt-2"
                  onClick={() => CommonHelper.copyToClipboard(pixCopiaCola)}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default InserirPixCreditoDetranModal

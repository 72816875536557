import React from 'react'
import Modal from './Modal'
import Container from '../layout/Container'
import { InputText } from 'primereact/inputtext'
import { Column } from 'primereact/column'
import ResponsiveColumn from './ResponsiveColumn'
import { showWarnMessage } from './Message'
import { Button } from 'primereact/button'
import List from '../../classes/List'
import DataList from './DataList'

export default class FiltroModal extends List {
  constructor (props) {
    super(props, props.model, props.service, '', '', 25)

    this.state = {
      ...this.state,
      stringCampos: ''
    }
  }

  componentDidMount () {
    this.addEventListeners()
    this.resetFiltro()

    let stringCampos = ''

    this.props.columns.forEach(column => {
      const { pesquisavel = true } = column

      if (!column.campo.includes('codigo') && pesquisavel) {
        stringCampos += (', ' + column.nome)
      }
    })

    stringCampos = stringCampos.replace(', ', '')

    this.setState({ stringCampos })
  }

  componentDidUpdate (prevProps) {
    if (!prevProps.visible && this.props.visible) {
      const { filtersDefault } = this.props

      if (filtersDefault) {
        const { filter } = this.state

        Object.getOwnPropertyNames(filtersDefault).forEach((propertyName) => {
          filter[propertyName] = filtersDefault[propertyName]
        })

        this.setState({ filter }, this.filtrarCasoNaoTiverRegistros)
      } else {
        this.setState({ filter: { descricaoFiltro: this.state.filter.descricaoFiltro } }, this.filtrarCasoNaoTiverRegistros)
      }
    }
  }

  filtrarCasoNaoTiverRegistros () {
    if (!this.state.list.length) {
      this.onFilter()
    }
  }

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.onFilter()
    }
  }

  handleSelect = (value) => {
    try {
      this.props.onSelect({ value })
      this.props.hide()
    } catch (error) {
      showWarnMessage(error.message)
    }
  }

  render () {
    const { filter, rows, totalRecords, first, list, page } = this.state

    return (
      <Modal
        header={this.props.title}
        width={this.props.width || '65'}
        visible={this.props.visible}
        onHide={this.props.hide}
      >
        <Container>
          <div className="grid align-items-center">
            <div className="col-12 md:col-10">
              <InputText
                placeholder={this.state.stringCampos}
                name="descricaoFiltro"
                value={filter.descricaoFiltro || ''}
                onChange={this.handleChangeFilter}
                className="w-full"
                onKeyDown={this.handleKeyDown}
              />
            </div>
            <div className="flex-grow-1" style={{ padding: '0.5rem' }}>
              <Button icon="pi pi-search" label="Filtrar" onClick={this.onFilter} className="w-full" />
            </div>
          </div>
          <DataList
            data={list}
            onSelect={this.onSelect}
            onRowSelect={(e) => this.handleSelect(e.data)}
            rows={rows}
            totalRecords={totalRecords}
            first={first}
            onPage={this.onPageChange}
            responsive={true}
            page={page}
            selectDatalist
          >
            {this.props.columns.map(column => {
              return (
                <Column
                  key={column.campo}
                  field={column.campo}
                  header={column.nome}
                  sortable
                  body={(data) => {
                    const propriedades = column.campo.split('.')
                    let valor = propriedades.reduce((a, prop) => a[prop], data)

                    if (valor === true) {
                      valor = 'Sim'
                    } else if (valor === false) {
                      valor = 'Não'
                    }

                    return <ResponsiveColumn column={column.nome} value={column.format ? column.format(valor) : valor} />
                  }}
                  style={
                    column.campo.includes('codigo')
                      ? { width: '10%', textAlign: 'center' }
                      : { width: (column.width || '100px'), textAlign: 'center' }
                  }
                />
              )
            })}
          </DataList>
        </Container>
      </Modal>
    )
  }
}

import React, { useEffect, useState } from 'react'
import Modal from './Modal'
import { Button } from 'primereact/button'
import { classNames } from 'primereact/utils'

function OptionsModal (props) {
  const [Component, setComponent] = useState(null)

  function showComponent (ComponentToShow) {
    return ComponentToShow ? <ComponentToShow /> : <></>
  }

  function makeButtons () {
    if (props.cadastros?.length) {
      const buttons = props.cadastros.map(cadastro => (
        <div className="col-6" key={cadastro.url}>
          <Button
            label={cadastro.label}
            className="w-full"
            onClick={() => {
              const ComponentToShow = () => (
                <cadastro.component
                  visible={true}
                  onRegister={props.onRegister}
                  hide={() => {
                    props.hide()
                    setComponent(null)
                  }}
                  edicaoHabilitada={true}
                />
              )
              setComponent(ComponentToShow)
            }}
          />
        </div>
      ))

      return buttons
    }
  }

  return (
    <Modal
      header="Selecione o tipo de cadastro"
      visible={props.visible}
      onHide={props.hide}
      width="25"
    >
      <div className="grid">
        {makeButtons()}
        {showComponent(Component)}
      </div>
    </Modal>
  )
}

export default function RegisterModal (props) {
  const [isCampoObrigatorio, setIsCampoObrigatorio] = useState(props.isCampoObrigatorio)
  const [visibleOptionsModal, setVisibleOptionsModal] = useState(false)
  const [visibleCadastroModal, setVisibleCadastroModal] = useState(false)
  const [visibleVisualizacaoModal, setVisibleVisualizacaoModal] = useState(false)

  useEffect(() => {
    if (isCampoObrigatorio === undefined) {
      for (const nomeProp of Object.getOwnPropertyNames(props)) {
        if (nomeProp === 'isFormFieldValid' && props.isFormFieldValid !== undefined) {
          setIsCampoObrigatorio(true)
        }
      }
    }
  }, [])

  function getClassName () {
    return classNames({ 'p-error': props.isFormFieldValid }, 'cadastrar-register-modal')
  }

  function getComponentByCgccpf () {
    let tipoComponent

    if (props.selected.cgccpf_pes?.length === 11) {
      tipoComponent = 'FÍSICA'
    } else {
      tipoComponent = 'JURÍDICA'
    }

    const Component = (props.ComponenteCadastro.find(componenteCadastro => (componenteCadastro.label.toUpperCase()).includes(tipoComponent))).component

    return (
      <Component
        visible={visibleVisualizacaoModal}
        hide={() => setVisibleVisualizacaoModal(false)}
        onRegister={props.onRegister}
        primaryKey={props.selected[props.primaryKeyName]}
        edicaoHabilitada={props.edicaoHabilitada}
      />
    )
  }

  return (
    <>
      {props.ComponenteCadastro.length === 1 && (
        <>
          <a className={getClassName()} onClick={() => setVisibleCadastroModal(true)}>{props.label}</a>{isCampoObrigatorio ? <label className="required"></label> : ''}
          <props.ComponenteCadastro
            visible={visibleCadastroModal}
            hide={() => setVisibleCadastroModal(false)}
            onRegister={props.onRegister}
            primaryKey={props.selected ? props.selected[props.primaryKeyName] : null}
            isVeiculoNovo={props.isVeiculoNovo}
            onlyVisualizacao={props.onlyVisualizacao}
            edicaoHabilitada={props.edicaoHabilitada}
          />
        </>
      )}
      {props.ComponenteCadastro.length > 1 && (
        <>
          {Object.keys(props.selected).length > 1 && (
            <>
              <a className={getClassName()} onClick={() => setVisibleVisualizacaoModal(true)}>{props.label}</a>{isCampoObrigatorio ? <label className="required"></label> : ''}
              {getComponentByCgccpf()}
            </>
          )}
          {Object.keys(props.selected).length <= 1 && (
            <>
              <a className={getClassName()} onClick={() => setVisibleOptionsModal(true)}>{props.label}</a>{isCampoObrigatorio ? <label className="required"></label> : ''}
              <OptionsModal
                visible={visibleOptionsModal}
                hide={() => setVisibleOptionsModal(false)}
                cadastros={props.ComponenteCadastro}
                onRegister={props.onRegister}
              />
            </>
          )}
        </>
      )}
    </>
  )
}

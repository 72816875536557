import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import React from 'react'
import List from '../../../../classes/List'
import DropdownMenu from '../../../../components/inputs/DropdownButton/DropdownMenu'
import Container from '../../../../components/layout/Container'
import Page from '../../../../components/layout/Page'
import DataList from '../../../../components/utils/DataList'
import { showErrorMessage, showSuccessMessage } from '../../../../components/utils/Message'
import { getEntradaZeroKmDTO } from '../../../../dtos/zeroKm/EntradaZeroKmDTO'
import { formatDate, formatDateTime, formatToCurrency } from '../../../../helpers/formaters'
import EntradaZeroKmService from '../../../../services/zeroKm/veiculos/EntradaZeroKmService'
import CancelarRejeicaoVeiculoModal from './CancelarRejeicaoVeiculoModal'
import RejeitarVeiculoModal from './RejeitarVeiculoModal'
import TrocarVeiculoModal from '../TrocarVeiculoModal'
import { IoWarningOutline } from 'react-icons/io5'
import { BsQuestionCircle } from 'react-icons/bs'
import ConfirmacaoOptionsModal from '../ConfirmacaoOptionsModal'
import ConfirmacaoErroModal from '../ConfirmacaoErroModal'
import CommonHelper from '../../../../helpers/CommonHelper'
import ConfirmarEntradaManualmenteModal from './ConfirmarEntradaManualmenteModal'
import Modal from '../../../../components/utils/Modal'
import TextInput from '../../../../components/inputs/TextInput'
import CheckBoxInput from '../../../../components/inputs/CheckBoxInput'
import TextAreaInput from '../../../../components/inputs/TextAreaInput'

class ConfirmarEntradaZeroKm extends List {
  constructor (props) {
    super(
      props,
      getEntradaZeroKmDTO,
      EntradaZeroKmService,
      '',
      '',
      ''
    )

    this.state = {
      ...this.state,
      selectedRows: [],
      rowsSelecionadasParaConfirmar: [],
      visibleConfirmarEntradaManualmenteModal: false,
      visibleCancelarRejeicaoVeiculoModal: false,
      visibleRejeitarVeiculoModal: false,
      visibleConfirmacaoErroModal: false,
      visibleTrocarVeiculoModal: false,
      visibleOptionModal: false,
      visibleCriarVeiculoMockModal: false,
      veiculoSelecionado: {},
      interval: null,
      itensAcoes: [],
      veiculoAcabado: true,
      veiculoMockAcabado: true,
      retornoApiRenave: ''
    }
  }

  async componentDidMount () {
    await this.getAll()
    this.addEventListeners()
    const interval = setInterval(() => {
      this.getAll()
    }, 300000)
    const itensAcoes = [
      {
        label: 'Rejeitar',
        command: (data) => {
          this.setState({ veiculoSelecionado: data, visibleRejeitarVeiculoModal: true })
        }
      }
    ]
    this.setState({ interval, itensAcoes })
  }

  componentWillUnmount () {
    clearInterval(this.state.interval)
  }

  rowClass = (data) => {
    return {
      'row-sem-xml': !data.numeronf,
      'row-processando': data.status_processandoapi
    }
  }

  handleConfirmarEntrada = async () => {
    if (this.state.rowsSelecionadasParaConfirmar.length > 0) {
      try {
        await EntradaZeroKmService.confirmarEntrada(this.state.rowsSelecionadasParaConfirmar)
        await this.getAll()
        this.setState({ rowsSelecionadasParaConfirmar: [], selectedRows: [] })
        showSuccessMessage('A sua solicitação de confirmação de entrada foi registrada com sucesso!')
      } catch (error) {
        showErrorMessage(error.message || 'Houve um erro ao confirmar a(s) entrada(s)')
      }
    }
  }

  handleSelectionChange = (e) => {
    const quantidadeSelecionaveis = this.state.list.filter(data => data.numeronf && !data.status_processandoapi).length
    let selected = e.value.filter(data => data.numeronf && !data.status_processandoapi)

    if (selected.length === this.state.selectedRows.length) {
      selected = []
    }

    if (selected.length === 0) {
      e.value = []
    }

    if (selected.length === quantidadeSelecionaveis) {
      e.value = this.state.list
    }

    this.setState({ selectedRows: e.value, rowsSelecionadasParaConfirmar: selected })
  }

  render () {
    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>
            Confirmação de entrada
            <BsQuestionCircle
              className="cursor-pointer icone-info confirmar-entrada"
              onClick={() =>
                window.open('https://sances.movidesk.com/kb/article/269506/veiculo-nao-aparece-na-confirmacao-de-entrada-o-que-fazer')
              }
            />
          </h4>
          <div className="page-header-buttons">
            {CommonHelper.isUsuarioAdmin() && (
              <Button
                label="Criar veículo mock"
                onClick={() => this.setState({ visibleCriarVeiculoMockModal: true })}
                className="mr-2"
              />
            )}
            {CommonHelper.isUsuarioAdmin() && (
              <Button
                label="Confirmar entrada manualmente"
                onClick={() => this.setState({ visibleConfirmarEntradaManualmenteModal: true })}
                className="mr-2"
              />
            )}
            <Button
              label="Cancelar rejeição de entrada"
              onClick={() => this.setState({ visibleCancelarRejeicaoVeiculoModal: true })}
              className="mr-2"
            />
            <Button
              icon="pi pi-refresh"
              onClick={() => this.onFilter()}
              className="mr-5"
            />
          </div>
        </div>
        <Container>
          <DataList
            className="selection-datalist"
            data={this.state.list}
            selected={this.state.selectedRows}
            onSelectionChange={e => this.handleSelectionChange(e)}
            dataKey="chassi"
            responsiveLayout="scroll"
            responsive={true}
            rowClassName={this.rowClass}
            selectionMode="checkbox"
          >
            <Column
              style={{ width: '5%' }}
              selectionMode="multiple"
            />
            <Column
              header="NF"
              field="numeronf"
              style={{ width: '8%', maxWidth: '8%' }}
              sortable
              headerClassName="numeronf"
            />
            <Column
              header="Série"
              field="serie"
              style={{ width: '5%', maxWidth: '5%' }}
              sortable
              headerClassName="serie"
            />
            <Column
              header="Data/Hora Cadastro BIN"
              field="dataHoraCadastro"
              body={data => data.dataHoraCadastro ? formatDateTime(data.dataHoraCadastro) : ''}
              style={{ width: '15%', maxWidth: '15%' }}
              sortable
              headerClassName="dataHoraCadastro"
            />
            <Column
              header="Chassi"
              field="chassi"
              body={data => <label>{data.chassi}</label>}
              style={{ width: '10%', maxWidth: '10%' }}
              sortable
              headerClassName="chassi"
            />
            <Column
              header="Marca/Modelo"
              field="marcamodelo"
              body={data => data.marcamodelo ? data.marcamodelo : <p>Aguardando importação do XML da nota da montadora</p>}
              style={{ width: '15%', maxWidth: '15%' }}
              sortable
              headerClassName="marcamodelo"
            />
            <Column
              header="Data Emissão"
              field="dataemissao"
              body={data => data.dataemissao ? formatDate(data.dataemissao) : ''}
              style={{ width: '10%', maxWidth: '10%' }}
              sortable
              headerClassName="dataemissao"
            />
            <Column
              header="Valor Total"
              field="valorentrada"
              body={data => data.valorentrada ? formatToCurrency(data.valorentrada) : ''}
              style={{ width: '10%', maxWidth: '10%' }}
              sortable={(data) => data.valorentrada ? true : false}
              headerClassName="valorentrada"
            />
            <Column
              header="Info"
              field="info"
              body={data => {
                if (data.status_processandoapi) {
                  return (
                    <i className="pi pi-spin pi-spinner info-selection"/>
                  )
                }

                if ((data.outrasNotas && data.outrasNotas.length > 0) && (data.erromensagem || data.errodetalhe)) {
                  return (
                    <IoWarningOutline
                      className="info-selection"
                      onClick={() => this.setState({ veiculoSelecionado: data, visibleOptionModal: true })}
                    />
                  )
                }

                if (data.outrasNotas && data.outrasNotas.length > 0) {
                  return (
                    <i
                      className="pi pi-info-circle info-selection"
                      onClick={() => this.setState({ veiculoSelecionado: data, visibleTrocarVeiculoModal: true })}
                    />
                  )
                }

                if (data.erromensagem || data.errodetalhe) {
                  return (
                    <IoWarningOutline
                      className="info-selection"
                      onClick={() => this.setState({ veiculoSelecionado: data, visibleConfirmacaoErroModal: true })}
                    />
                  )
                }
              }}
              style={{ width: '10%', maxWidth: '7%' }}
            />
            <Column
              header="Ações"
              body={(data) =>
                <DropdownMenu
                  items={this.state.itensAcoes}
                  icon="pi pi-ellipsis-v"
                  data={data}
                  onlyIcon
                />
              }
              style={{ width: '7%', maxWidth: '7%' }}
            />
          </DataList>
          <div className="table-footer">
            <div className="grid col-12 justify-content-end">
              <Button
                label="Confirmar entradas selecionadas"
                className="confirm-button"
                onClick={() => this.handleConfirmarEntrada()}
              />
            </div>
          </div>
        </Container>
        <ConfirmarEntradaManualmenteModal
          visible={this.state.visibleConfirmarEntradaManualmenteModal}
          onHide={() => this.setState({ visibleConfirmarEntradaManualmenteModal: false })}
        />
        <ConfirmacaoErroModal
          visible={this.state.visibleConfirmacaoErroModal}
          onHide={() => this.setState({ visibleConfirmacaoErroModal: false })}
          mensagem={this.state.veiculoSelecionado.erromensagem || this.state.veiculoSelecionado.errodetalhe}
        />
        <ConfirmacaoOptionsModal
          visible={this.state.visibleOptionModal}
          onHide={() => this.setState({ visibleOptionModal: false })}
          openTrocarNotaModal={() => this.setState({ visibleTrocarVeiculoModal: true })}
          openConfirmacaoErroModal={() => this.setState({ visibleConfirmacaoErroModal: true })}
        />
        <TrocarVeiculoModal
          visible={this.state.visibleTrocarVeiculoModal}
          onHide={() => this.setState({ visibleTrocarVeiculoModal: false })}
          veiculo={this.state.veiculoSelecionado}
          getAll={this.getAll}
        />
        <CancelarRejeicaoVeiculoModal
          visible={this.state.visibleCancelarRejeicaoVeiculoModal}
          onHide={() => this.setState({ visibleCancelarRejeicaoVeiculoModal: false })}
          getAll={this.getAll}
        />
        <RejeitarVeiculoModal
          visible={this.state.visibleRejeitarVeiculoModal}
          chassi={this.state.veiculoSelecionado.chassi}
          getAll={this.getAll}
          onHide={() => this.setState({ visibleRejeitarVeiculoModal: false })}
        />
        <Modal
          header="Criar veículo mock"
          width={45}
          onHide={() => this.setState({ visibleCriarVeiculoMockModal: false })}
          visible={this.state.visibleCriarVeiculoMockModal}
          footer={
            <Button
              label="Confirmar"
              onClick={async () => {
                let dados = await EntradaZeroKmService.criarVeiculoMock(this.state.sufixoChassi, this.state.veiculoMockAcabado)
                this.setState({ retornoApiRenave: JSON.stringify(dados) })
                await this.getAll()
              }}
            />
          }
        >
          <div className="formgrid grid">
            <div className="field col-12 md:col-6">
              <TextInput
                label="Sufixo chassi (últimos 4 digitos do chassi)"
                value={this.state.sufixoChassi}
                className={'inputfield w-full'}
                onChange={(e) => this.setState({ sufixoChassi: e.target.value })}
              />
            </div>
            <div className="field col-12 md:col-6 flex align-items-center mt-2">
              <CheckBoxInput
                label="Veículo acabado?"
                value={this.state.veiculoMockAcabado}
                checked={this.state.veiculoMockAcabado}
                onChange={() => this.setState({ veiculoMockAcabado: !this.state.veiculoMockAcabado })}
              />
            </div>

            <div className="field col-12">
              <TextAreaInput
                label="Resposta do Renave"
                className={'inputfield w-full'}
                value={this.state.retornoApiRenave}
              />
            </div>
          </div>
        </Modal>
      </Page>
    )
  }
}

export default ConfirmarEntradaZeroKm

import { Button } from 'primereact/button'
import React from 'react'
import { showErrorMessage, showSuccessMessage } from '../../../components/utils/Message'
import Modal from '../../../components/utils/Modal'
import CommonHelper from '../../../helpers/CommonHelper'
import AtpvService from '../../../services/atpv/AtpvService'
import ImpressoesMontadoraService from '../../../services/montadora/ImpressoesMontadoraService'
import TermoDeSaidaService from '../../../services/termoDeSaida/TermoDeSaidaService'

const ImprimirAtpveTermoSaidaModal = ({ visible, onHide, chassi, veiculoAcabado, idEstoque }) => {
  function isMontadora () {
    return window.location.pathname.includes('montadora')
  }

  const handleBaixarAtpve = async () => {
    try {
      let pdfAtpvBase64

      if (isMontadora()) {
        pdfAtpvBase64 = await ImpressoesMontadoraService.getATPVE(chassi)
      } else {
        pdfAtpvBase64 = (await AtpvService.getAtpvNovo(chassi)).data.pdfAtpvBase64
      }

      const pdfAtpvBase64Atualizado = await AtpvService.atualizarAtpv(pdfAtpvBase64)
      CommonHelper.downloadFile('data:application/pdf;base64,', pdfAtpvBase64Atualizado, `ATPV-${chassi}.pdf`)
      showSuccessMessage('ATPV-e baixado com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Houve um erro ao baixar o ATPV-e')
    }
  }

  const handleBaixarTermoDeSaida = async () => {
    try {
      let pdfTermoSaidaBase64

      if (isMontadora()) {
        pdfTermoSaidaBase64 = await ImpressoesMontadoraService.getTermoSaida(idEstoque)
      } else {
        pdfTermoSaidaBase64 = (await TermoDeSaidaService.getTermoDeSaidaVeiculoInacabado(idEstoque)).data.pdfBase64
      }

      CommonHelper.downloadFile('data:application/pdf;base64,', pdfTermoSaidaBase64, `TermoDeSaida-${chassi}.pdf`)
      showSuccessMessage('Termo de saída baixado com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Houve um erro ao baixar o termo de saída')
    }
  }

  const handleEnviarAtpveEmail = async () => {
    try {
      await AtpvService.enviarAtpvEmail(chassi, isMontadora())
      showSuccessMessage('Email com o ATPV-e enviado com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Houve um erro ao enviar o email com o seu ATPV-e!')
    }
  }

  const handleEnviarTermoSaidaEmail = async () => {
    try {
      await TermoDeSaidaService.enviarTermoDeSaidaPorEmail(chassi, idEstoque, isMontadora())
      showSuccessMessage('Email com o termo de saída enviado com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Houve um erro ao enviar o email com o seu termo de saída!')
    }
  }

  return (
    <Modal
      header={`Imprimir ${veiculoAcabado ? 'ATPV-e' : 'termo de saída'} do veículo: ${chassi}`}
      visible={visible}
      onHide={onHide}
      width={40}
    >
      <div className="col-12 grid justify-content-center mt-5">
        {/* {!(veiculoAcabado && isMontadora()) && ( */}
        <Button
          label="Enviar por E-mail"
          className="mr-8"
          onClick={async () => veiculoAcabado ? await handleEnviarAtpveEmail() : await handleEnviarTermoSaidaEmail()}
        />
        {/* )} */}
        <Button
          label="Baixar agora"
          onClick={async () => veiculoAcabado ? await handleBaixarAtpve() : await handleBaixarTermoDeSaida()}
        />
      </div>
    </Modal>
  )
}

export default ImprimirAtpveTermoSaidaModal

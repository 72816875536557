import React from 'react'
import Modal from '../../../components/utils/Modal'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import CommonHelper from '../../../helpers/CommonHelper'
import { formatToCurrency } from '../../../helpers/formaters'

function DebitosVeiculoModal ({ visible, onHide, boleto }) {
  const listaBoletos = (boleto ?? []).map((bol) => ({
    codbar_bve: bol.codbar_bve,
    valor_bve: bol.valor_bve,
    venci_bve: CommonHelper.dateToBrazilian(bol.venci_bve)
  }))

  return (
    <Modal
      visible={visible}
      onHide={onHide}
      header="Débitos do veículo"
      width={50}
    >
      <div>
        {listaBoletos.length > 0 ? (
          <DataTable value={listaBoletos}>
            <Column
              field="codbar_bve"
              header="Código de Barras"
              style={{ width: '60%' }}
            />
            <Column
              field="valor_bve"
              header="Valor"
              style={{ width: '25%' }}
              body={data => formatToCurrency(data.valor_bve)}
            />
            <Column
              field="venci_bve"
              header="Vencimento"
              style={{ width: '15%' }}
            />
          </DataTable>
        ) : (
          <div style={{ textAlign: 'center' }}>Nenhum débito encontrado.</div>
        )}
      </div>
    </Modal>
  )
}

export default DebitosVeiculoModal

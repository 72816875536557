import { Button } from 'primereact/button'
import { Divider } from 'primereact/divider'
import React, { useEffect, useState } from 'react'
import DateInput from '../../../../components/inputs/DateInput'
import SearchInput from '../../../../components/inputs/SearchInput'
import TextInput from '../../../../components/inputs/TextInput'
import { showErrorMessage, showWarnMessage } from '../../../../components/utils/Message'
import { getEmpresaDTO } from '../../../../dtos/cadastro/EmpresaDTO'
import EmpresaModal from '../../../../forms/cadastro/empresa/EmpresaModal'
import VeiculoModal from '../../../../forms/cadastro/veiculo/VeiculoModal'
import { formatCnpj } from '../../../../helpers/formaters'
import EmpresaService from '../../../../services/cadastro/EmpresaService'
import EstoqueUsadosService from '../../../../services/veiculosUsados/EstoqueUsadosService'
import SolicitacaoTransferenciaFilialService from '../../../../services/veiculosUsados/SolicitacaoTransferenciaFilialService'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

function SolicitacaoTransferenciaFilial ({ veiculo, filialProp, kmVeiculo }) {
  const [filial, setFilial] = useState(null)
  const [sugestaoFiliais, setSugestaoFiliais] = useState([])
  const [visibleVeiculoModal, setVisibleVeiculoModal] = useState(false)
  const [dataSaida, setDataSaida] = useState(new Date())
  const [kmAtual, setKmAtual] = useState(0)
  const [codigoVei, setCodigoVei] = useState(0)
  const router = useHistory()

  useEffect(() => {
    setFilial(filialProp)
  }, [filialProp])

  useEffect(() => {
    setKmAtual(kmVeiculo)
  }, [kmVeiculo])

  const sugerirFiliais = async () => {
    try {
      const empresaService = new EmpresaService()

      const filials = (await empresaService.filter(`apenasFiliais=true&descricaoFiltro=${filial.nomfan_emp}`)).data

      if (filials.length === 0) {
        showWarnMessage('Não foi encontrada nenhuma filial!')
      }

      setSugestaoFiliais(filials)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir uma filial!')
    }
  }

  function handleChangeFilial (event) {
    setFilial({ nomfan_emp: event.target.value })
  }

  function handleOnRegisterFilial (filialRegistrada) {
    setFilial(filialRegistrada)
  }

  async function enviarSolicitacao () {
    try {
      if (!filial?.codigo_emp) {
        showWarnMessage('Por favor informe um filial válido!')
        return
      }

      if (!dataSaida) {
        showWarnMessage('Por favor informe uma data de saída válida!')
        return
      }

      await SolicitacaoTransferenciaFilialService.solicitacaoTransferenciaFilial(kmAtual, dataSaida, veiculo, filial)
      router.push('/estoque-veiculos-usados')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar enviar a solicitação')
    }
  }

  const setRetorno = (response) => {
    setState((prevState) => ({
      ...prevState,
      data: response.data,
      list: response.data,
      totalRecords: response.meta?.total,
      totalPages: response.meta?.last_page,
      quantidades: response.quantidades
    }))
  }

  const toFilter = () => {
    return stringify(state.filter, { skipNull: true, skipEmptyString: true, encode: false })
  }

  const onFilter = async () => {
    try {
      let response = await EstoqueUsadosService.filter(toFilter(), state.page, state.rows)
      setRetorno(response)
    } catch (error) {
      console.error(error.message || 'Houve um erro ao filtrar os registros!')
    }
  }

  return veiculo && (
    <div className="formgrid grid">
      <div className="field col-12">
        <h3 className="solicitacao-entrada-header mt-0">INFORMAÇÕES DA FILIAL</h3>
        <Divider/>
        <div className="formgrid grid">
          <div className="field col-8 mb-2">
            <SearchInput
              //AutoComplete
              label="Filial"
              placeholder="Filial destino da transferência"
              field="nomfan_emp"
              value={filial?.nomfan_emp && filial}
              suggestions={sugestaoFiliais}
              completeMethod={sugerirFiliais}
              onChange={handleChangeFilial}
              onSelect={(e) => setFilial(e.value)}
              className="inputfield w-full"
              //RegisterModal
              onlyVisualizacao={true}
              ComponenteCadastro={EmpresaModal}
              onRegister={handleOnRegisterFilial}
              primaryKeyName="codigo_emp"
              selected={filial}
              //FiltroModal
              filtroTitle="Pesquisa de filiais"
              filtersDefault={{ apenasFiliais: true }}
              service={EmpresaService}
              model={getEmpresaDTO}
              columns={[
                { campo: 'nomfan_emp', nome: 'Nome' },
                { campo: 'cnpj_emp', nome: 'CNPJ', format: formatCnpj }
              ]}
            />
          </div>
          <div className="field col-4 mb-2">
            <TextInput
              label="CNPJ"
              placeholder="CNPJ"
              value={formatCnpj(filial?.cnpj_emp)}
              className="inputfield w-full"
              disabled={true}
            />
          </div>
        </div>
        <Divider/>
        <h3 className="solicitacao-entrada-header">INFORMAÇÕES DO VEÍCULO</h3>
        <Divider/>
        <div className="formgrid grid">
          <div className="field col-8">
            <div>
              <h1>Veículo</h1>
              <div className="mt-2">
                <label>Marca/Modelo: </label><span>{veiculo.marcaModelo}</span>
              </div>
              <div className="mt-1">
                <label>Ano: </label><span>{veiculo.anoFab}/{veiculo.anoMod}</span>
              </div>
              <div className="mt-1">
                <label>Placa/Chassi: </label><span className="black underline cursor-pointer ml-1" onClick={() => {
                  if (!veiculo.codigoVeiculo) {
                    showWarnMessage('Não foi possível encontrar o veículo nos cadastros, por favor cadastre o veículo!')
                    return
                  }
                  setVisibleVeiculoModal(true)
                  setCodigoVei(veiculo.codigoVeiculo)
                }}>{veiculo.placa || veiculo.chassi}</span>
              </div>
              <div className="mt-1">
                <label>Renavam: </label><span>{veiculo.renavam}</span>
              </div>
            </div>
          </div>
          <div className="field col-2">
            <TextInput
              label="KM do veículo"
              placeholder="Quilometragem atual"
              value={kmAtual}
              onChange={(e) => setKmAtual(e.target.value)}
              className="inputfield w-full"
            />
          </div>
          <div className="field col-2">
            <DateInput
              label="Data saída estoque"
              placeholder="Data saída estoque"
              value={dataSaida}
              onChange={(e) => setDataSaida(e.value)}
              className="inputfield w-full"
            />
          </div>
        </div>
        <div className="flex justify-content-center">
          <Button
            label="Enviar solicitação"
            className="mt-3"
            onClick={async () => enviarSolicitacao()}
          />
        </div>
      </div>
      <VeiculoModal
        visible={visibleVeiculoModal}
        hide={() => setVisibleVeiculoModal(false)}
        isVeiculoNovo={false}
        primaryKey={codigoVei}
        onRegister={async () => await onFilter()}
        edicaoHabilitada={true}
      />
    </div>
  )
}

export default SolicitacaoTransferenciaFilial

import './_tablePreferences.scss'
import React, { useEffect, useState } from 'react'
import { InputSwitch } from 'primereact/inputswitch'
import UsuarioService from '../../services/cadastro/UsuarioService'
import { formatCnpj } from '../../helpers/formaters'
import { getStore } from '../../redux/store'
import { Button } from 'primereact/button'
import { showErrorMessage, showSuccessMessage } from '../../components/utils/Message'
import EmpresaService from '../../services/cadastro/EmpresaService'

function ConfiguracaoModulo () {
  const [configuracaoModulos, setConfiguracaoModulos] = useState([])

  useEffect(() => {
    async function fetchData () {
      const empresasAcessoUsuario = await UsuarioService.empresasAcessoUsuarioLogado()

      const empresasGrupo = empresasAcessoUsuario.filter(empresa => empresa.codmat_emp === getStore().empresaLogada.codmat_emp)

      setConfiguracaoModulos(empresasGrupo.map(empresa => {
        return {
          codigoEmp: empresa.codigo_emp,
          nomeEmp: empresa.nomfan_emp,
          cnpjEmp: empresa.cnpj_emp,
          renNovos: empresa.rennov_emp,
          renUsados: empresa.renusa_emp,
          renIte: empresa.renite_emp,
          renMontadora: empresa.renmon_emp
        }
      }))
    }
    fetchData()
  }, [])

  function marcarDesmarcarTodas (nomeConfiguracao) {
    const algumaEstaDesmarcada = Boolean(configuracaoModulos.find(config => !config[nomeConfiguracao]))

    setConfiguracaoModulos(configuracaoModulos.map(config => {
      config[nomeConfiguracao] = algumaEstaDesmarcada

      return config
    }))
  }

  async function salvarConfiguracao () {
    try {
      await EmpresaService.updateConfiguracaoModulos(configuracaoModulos)
      showSuccessMessage('Módulos atualizados com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar salvar as configurações')
    }
  }

  return (
    <div>
      <div className="flex justify-content-between form-header my-6">
        <h1>Configuração de módulos</h1>
      </div>
      <div className="">
        <div className="flex mb-3 justify-content-between mt-3">
          <label className="label-nome-empresa">Nome da empresa</label>
          <div className="container-label-configuracao-modulos">
            <label className="label-configuracao-modulos" onClick={() => marcarDesmarcarTodas('renNovos')}>Zero Km</label>
            <label className="label-configuracao-modulos" onClick={() => marcarDesmarcarTodas('renUsados')}>Usados</label>
            <label className="label-configuracao-modulos" onClick={() => marcarDesmarcarTodas('renIte')}>ITE</label>
            <label className="label-configuracao-modulos" onClick={() => marcarDesmarcarTodas('renMontadora')}>Montadora</label>
          </div>
        </div>
        {configuracaoModulos.map((configuracao) => {
          return (
            <React.Fragment key={configuracao.codigoEmp}>
              <div className="form-divider"></div>
              <div className="flex justify-content-between configuracao-modulos-row">
                <p className="p-text-bold no-margin flex align-items-center configuracao-modulos-row-nome-empresa">{configuracao.nomeEmp} - ({formatCnpj(configuracao.cnpjEmp)})</p>
                <div className="flex align-options table-preferences-container">
                  <div className="input-switch-container">
                    <InputSwitch
                      className="table-preferences-switch"
                      checked={configuracao.renNovos}
                      onChange={(e) => {
                        setConfiguracaoModulos(configuracaoModulos.map(config => {
                          if (config.codigoEmp === configuracao.codigoEmp) {
                            config.renNovos = e.value
                          }

                          return config
                        }))
                      }} />
                  </div>
                  <div className="input-switch-container">
                    <InputSwitch
                      className="table-preferences-switch"
                      checked={configuracao.renUsados}
                      onChange={(e) => {
                        setConfiguracaoModulos(configuracaoModulos.map(config => {
                          if (config.codigoEmp === configuracao.codigoEmp) {
                            config.renUsados = e.value
                          }

                          return config
                        }))
                      }} />
                  </div>
                  <div className="input-switch-container">
                    <InputSwitch
                      className="table-preferences-switch"
                      checked={configuracao.renIte}
                      onChange={(e) => {
                        setConfiguracaoModulos(configuracaoModulos.map(config => {
                          if (config.codigoEmp === configuracao.codigoEmp) {
                            config.renIte = e.value
                          }

                          return config
                        }))
                      }} />
                  </div>
                  <div className="input-switch-container">
                    <InputSwitch
                      className="table-preferences-switch"
                      checked={configuracao.renMontadora}
                      onChange={(e) => {
                        setConfiguracaoModulos(configuracaoModulos.map(config => {
                          if (config.codigoEmp === configuracao.codigoEmp) {
                            config.renMontadora = e.value
                          }

                          return config
                        }))
                      }} />
                  </div>
                </div>
              </div>
            </React.Fragment>
          )
        })}

      </div>
      <div className="form-divider"></div>
      <div className="flex justify-content-end">
        <Button
          label="Salvar"
          onClick={async () => await salvarConfiguracao()}
        />
      </div>
    </div>
  )
}

export default ConfiguracaoModulo

import React, { Component } from 'react'
import TextInput from '../../components/inputs/TextInput'
import { Button } from 'primereact/button'
import LoginService from '../../services/publicos/LoginService'
import { Toast } from 'primereact/toast'
import { login } from '../../helpers/auth'
import queryString from 'query-string'
import CommonHelper from '../../helpers/CommonHelper'
import EmpresaService from '../../services/cadastro/EmpresaService'
import RedefinirSenhaModal from './RedefinirSenhaModal'
import ChangePasswordObrigatorioModal from '../../components/utils/ChangePasswordObrigatorioModal'
import TrocarEmpresaModal from './TrocarEmpresaModal'
import { Carousel } from 'primereact/carousel'

export class Login extends Component {
  constructor (props) {
    super(props)
    this.loginService = new LoginService()
    this.empresaService = new EmpresaService()
    this.state = {
      senha: '',
      email: '',
      loading: false,
      visibleCompanySelect: false,
      visibleRedefinirSenhaModal: false,
      visibleAlterarSenhaModal: false,
      usuario: {},
      companies: [],
      selectedCompany: null,
      filtroCompanies: '',
      renderCompaniesList: []
    }
  }

  handleQueryParamas = () => {
    if (!this.props.location.search) return

    const { email } = queryString.parse(this.props.location.search)
    this.setState({ email })
  }

  componentDidMount () {
    this.handleQueryParamas()
  }

  handleKeyPress = e => {
    if (e.keyCode === 13) { // Tecla ENTER
      this.onEmpresasAcesso()
    }
  }

  onEmpresasAcesso = async () => {
    this.setState({ loading: true })
    try {
      const usuario = await LoginService.getUsuarioByEmailSenha({
        password: this.state.senha,
        email: this.state.email
      })

      if (usuario.altsen_usu) {
        this.setState({ usuario, visibleAlterarSenhaModal: true })
      } else {
        const companies = await this.empresaService.getEmpresasAcesso({
          password: this.state.senha,
          email: this.state.email
        })

        const empresaDespachante = companies.find((empresa) => empresa.isdesp_emp)

        if (empresaDespachante && this.state.email !== 'suporte@sances.com.br') {
          this.setState({
            selectedCompany: empresaDespachante,
            loading: false
          }, this.onLogin)
          return
        }

        if (companies.length === 1) {
          this.setState({
            selectedCompany: companies[0],
            loading: false
          }, this.onLogin)
          return
        }

        this.setState({
          visibleCompanySelect: true,
          loading: false,
          companies: companies
        })
      }
    } catch (e) {
      this.setState({ loading: false })
      this.toast.show({
        severity: 'error',
        summary: 'Login/Senha inválidos',
        detail: e.message || 'Favor confira seus dados e tente novamente'
      })
    }
  }

  onLogin = async () => {
    try {
      let token = await this.loginService.login({
        password: this.state.senha,
        email: this.state.email,
        codigo_emp: this.state.selectedCompany.codigo_emp
      })
      login(token)

      const { state } = this.props.history.location

      // Verifica state.from ou localStorage
      const redirectPathname = state?.from?.pathname || localStorage.getItem('redirectAfterLogin') || '/home'
      const redirectSearch = state?.from?.search || '' // Query params, se existirem

      // Redireciona e limpa o localStorage
      this.props.history.push(`${redirectPathname}${redirectSearch}`)
      localStorage.removeItem('redirectAfterLogin')
    } catch (e) {
      this.toast.show({
        severity: 'error',
        summary: 'Erro',
        detail: e.message || 'Ops! Ocorreu um erro ao fazer o login!'
      })
    }
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleConfirm = () => {
    this.setState({ visibleCompanySelect: false })
    this.onLogin()
  }

  render () {
    const imagensTemplate = (imagem) => {
      return (
        <div className="border-1 surface-border border-round m-2 text-center py-5 px-3">
          <div className="mb-3">
            {window.location.hostname === 'localhost' ? (
              <object data={`http://${window.location.hostname}:3000/assets/layout/images/${imagem.image}`} alt={imagem.name}></object>
            )
              : <object data={`https://${window.location.hostname}/assets/layout/images/${imagem.image}`} alt={imagem.name}></object>
            }
          </div>
          <div>
            <h4 className="mb-1 carousel-name">{imagem.name}</h4>
          </div>
        </div>
      )
    }

    const { senha, email, loading } = this.state
    const imagens = [
      { name: 'Veículos Usados', image: 'veiculo-usado.svg' },
      { name: 'Veículos Zero KM', image: 'veiculo-zerokm.svg' },
      { name: 'Venda Direta', image: 'venda-direta.svg' },
      { name: 'Implementadoras, Transportadoras e Encarroçadoras', image: 'ite.svg' }
    ]

    return (

      <>
        <div className="login-page">
          <div className="login-body">
            {CommonHelper.isDesktop() && (

              <div className="field col-7 login-logo">
                <div className="logo">
                  <object data="/assets/layout/images/Logo_RenaveAuto_com_texto.svg"></object>
                </div>
                <div className="logo-body">
                  <Carousel value={imagens} numVisible={1} className="custom-carousel" circular
                    autoplayInterval={8000} itemTemplate={imagensTemplate} />
                </div>
              </div>
            )}

            <div className={(CommonHelper.isDesktop() ? 'field col-5' : '') + ' login-form'}>
              {!CommonHelper.isDesktop() && (
                <div className="logo">
                  <object data="/assets/layout/images/Logo_RenaveAuto_com_texto.svg"></object>
                </div>
              )}
              <object className="mb-2 icone-login" data="/assets/layout/images/icon-person.svg"></object>
              <div className="formgrid grid justify-content-center ">
                <h3 className="login-welcome">Bem-vindo!</h3>
                <div className="field col-12">
                  <TextInput
                    label="Usuário"
                    name="email"
                    value={email}
                    type="email"
                    className="inputfield w-full"
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyPress}
                    autoFocus
                  />
                </div>
                <div className="field col-12" style={{ marginBottom: '0px' }}>
                  <TextInput
                    type="password"
                    label="Senha"
                    name="senha"
                    value={senha}
                    className="inputfield w-full"
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyPress} />
                </div>
                <div className="flex justify-content-end" style={{ width: '100%' }}>
                  <small className="login-smaller-link  mr-3 mb-4" onClick={() => this.setState({ visibleRedefinirSenhaModal: true })}>Esqueci minha senha.</small>
                </div>
                <div className="field col-12 no-margin">
                  <Button
                    className="login-button w-full"
                    label="Entrar"
                    icon={loading ? 'pi pi-spin pi-spinner' : 'pi pi-sign-in'}
                    onClick={this.onEmpresasAcesso}
                  />
                </div>
              </div>
            </div>
          </div>
        </div >
        <TrocarEmpresaModal
          visible={this.state.visibleCompanySelect}
          onHide={() => this.setState({ visibleCompanySelect: false })}
          onConfirm={this.handleConfirm}
          handleSelectCompany={(company) => this.setState({ selectedCompany: company })}
          selectedCompany={this.state.selectedCompany}
          companies={this.state.companies}
          emailUsuario={this.state.email}
        />
        <RedefinirSenhaModal
          visible={this.state.visibleRedefinirSenhaModal}
          onHide={() => this.setState({ visibleRedefinirSenhaModal: false })}
        />
        <ChangePasswordObrigatorioModal
          visible={this.state.visibleAlterarSenhaModal}
          usuario={this.state.usuario}
          hide={(novaSenha) => {
            this.setState({ visibleAlterarSenhaModal: false, senha: novaSenha })
            this.onEmpresasAcesso()
          }}
        />
        <Toast ref={(el) => this.toast = el} />
      </>
    )
  }
}

import ServiceBase, { ComunicaRenave } from '../base/ServiceBase'

export default class SaidaMontadoraService {
  async getAll (page, perPage) {
    const dados = await ServiceBase.getBase(`montadora/getPendentesSaida?page=${page}&perPage=${perPage}`, ComunicaRenave)
    return dados
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`montadora/getPendentesSaida?${query}&page=${page}&perPage=${perPage}`, ComunicaRenave)
  }

  static async confirmarSaida (listaSaidas) {
    const itensList = listaSaidas.map((saida) => ({ codigo: saida.codigo, codigoxmlimport: saida.codigo, codConfSaida: saida.codConfSaida, chassi: saida.chassi, cnpjvendedora: saida.cnpjvendedora, cnpjentregadora: saida.cnpjentregadora, beneficiofiscal: saida.beneficiofiscal, leasing: saida.leasing }))
    return await ServiceBase.postBase('montadora/confirmarSaida', { itensList }, ComunicaRenave)
  }

  static async cancelarSaida (data) {
    return await ServiceBase.postBase('montadora/cancelarSaida/', data, ComunicaRenave)
  }

  // static async cancelarSaidaInacabado (idEstoque) {
  //   return await ServiceBase.postBase(`montadora/cancelarSaidaInacabado/${idEstoque}`, ComunicaRenave)
  // }

  static async getAllBeneficioFiscal () {
    return await ServiceBase.getBase('montadora/getAllBeneficioFiscal', ComunicaRenave)
  }
}

import { Component } from 'react'
import { showErrorMessage } from '../components/utils/Message'
import { stringify } from 'query-string'

export default class List extends Component {
  constructor (props, Model, Service, resource, primaryKey = 'id', opcaoSelecionada = 't') {
    super(props)
    this.model = Model
    this.modelService = new Service()
    this.resource = resource
    this.primaryKey = primaryKey
    this.state = {
      visible: false,
      visibleConfirm: false,
      filterCollapsed: true,
      selected: this.model,
      filter: this.model,
      list: [],
      rows: 25,
      page: 1,
      first: 0,
      totalRecords: 0,
      totalPages: 0,
      opcaoSelecionada: opcaoSelecionada,
      labelConfirmado: null,
      labelAtivo: null,
      data: [],
      isStatic: false,
      tableSelected: {},
      quantidades: {},
      expandedRows: null
    }

    this.columns = null
  }

  async componentDidMount () {
    await this.getAll()
    this.addEventListeners()
    this.resetFiltro()
  }

  addEventListeners = () => {
    if (this.columns) {
      const campos = this.columns.map(column => column.key)

      for (const campo of campos) {
        const elemento = document.querySelector(`.${campo}`)

        if (!elemento || !campo) continue

        elemento.addEventListener('click', () => {
          const { filter } = this.state
          filter.ordemCampo = filter.ordemCampo === `${campo}|asc` ? `${campo}|desc` : `${campo}|asc`
          this.setState({ filter }, this.onFilter)
        })
      }
    }
  }

  setRetorno = (response) => {
    this.setState({
      data: response.data,
      list: response.data,
      totalRecords: response.meta?.total,
      totalPages: response.meta?.last_page,
      quantidades: response.quantidades
    })
  }

  getAll = async () => {
    try {
      let response = await this.modelService.getAll(this.state.page, this.state.rows)
      this.setRetorno(response)
    } catch (e) {
      showErrorMessage(e.message || 'Houve um erro ao listar os registros!')
    }
  }

  onFilter = async () => {
    try {
      let response = await this.modelService.filter(this.toFilter(), this.state.page, this.state.rows)
      this.setRetorno(response)
    } catch (error) {
      showErrorMessage(error.message || 'Houve um erro ao filtrar os registros!')
    }
  }

  resetFiltro () {
    const filter = {}
    filter.descricaoFiltro = undefined
    this.setState({ filter })
  }

  toFilter () {
    return stringify(this.state.filter, { skipNull: true, skipEmptyString: true, encode: false })
  }

  onPageChange = async data => {
    const first = data.first
    const page = data.page + 1
    await this.setState({ first, page })
    this.onFilter()
  }

  onView = async (selected) => {
    this.props.history.push(`/${this.resource}/${selected[this.primaryKey]}`)
  }

  handleChangeFilter = event => {
    const filter = this.state.filter
    filter[event.target.name] = event.target.value
    this.setState({ filter })
  }

  onSelect = selected => {
    this.setState({ selected })
  }

  setColumns = (columns) => {
    this.columns = columns
  }

  onNew = () => {
    this.props.history.push(`/${this.resource}`)
  }

  handleRowExpansion = (e) => {
    let expandedRows = this.state.expandedRows
    if (expandedRows === null) {
      expandedRows = [e]
    } else if (expandedRows[0] === e) {
      expandedRows = null
    } else {
      expandedRows = [e]
    }
    this.setState({ expandedRows, selected: e })
  }
}

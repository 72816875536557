import { classNames } from 'primereact/utils'
import React, { useEffect, useState } from 'react'
import DateTimeInput from '../../../components/inputs/DateTimeInput'
import NumberInput from '../../../components/inputs/NumberInput'
import SelectInput from '../../../components/inputs/SelectInput'
import TextInput from '../../../components/inputs/TextInput'
import FieldErrorMessage from '../../../components/utils/FieldErrorMessage'
import RequiredLabel from '../../../components/utils/RequiredLabel'
import { useValidateInput } from '../../../helpers/useValidateInput'
import VeiculoService from '../../../services/cadastro/VeiculoService'

const DadosPrincipaisVei = ({ form, edicaoHabilitada, isVeiculoNovo }) => {
  const { isFormFieldValid, getFormErrorMessage } = useValidateInput(form)
  const setFieldTouched = (e) => form.setFieldTouched(e.target.name)
  const [tiposCrv, setTiposCrv] = useState([])

  useEffect(async () => {
    await getTiposCrv()
    form.setFieldValue('isnovoVei', isVeiculoNovo)
  }, [])

  useEffect(async () => {
    if (form.values.tipcrvVei && tiposCrv.length > 0 && Object.keys(form.values.tipoCrv).length === 0) {
      const tipoCrv = tiposCrv.filter((tipoCrv => tipoCrv.codigo_tcr === form.values.tipcrvVei)).shift()
      await form.setFieldValue('tipoCrv', tipoCrv)
    }
  }, [tiposCrv, form.values.tipcrvVei, form.values.tipoCrv])

  const getTiposCrv = async () => {
    const tiposCrv = await VeiculoService.getTiposCrv()
    setTiposCrv(tiposCrv)
  }

  return (
    <div className="formgrid grid">
      {!isVeiculoNovo && (
        <>
          <div className="field col-12 md:col-2">
            <RequiredLabel label="Placa" valid={isFormFieldValid('placaVei')}/>
            <TextInput
              placeholder="Placa"
              name="placaVei"
              value={form.values.placaVei}
              onChange={form.handleChange}
              mask="aaa-9*99"
              disabled={!edicaoHabilitada}
              className={classNames({ 'p-invalid': isFormFieldValid('placaVei') }, 'inputfield w-full')}
              onBlur={setFieldTouched}
            />
            <FieldErrorMessage message={getFormErrorMessage('placaVei')}/>
          </div>
          <div className="field col-12 md:col-2">
            <RequiredLabel label="Renavam" valid={isFormFieldValid('renavaVei')}/>
            <TextInput
              placeholder="Renavam"
              name="renavaVei"
              value={form.values.renavaVei}
              onChange={form.handleChange}
              disabled={!edicaoHabilitada}
              className={classNames({ 'p-invalid': isFormFieldValid('renavaVei') }, 'inputfield w-full')}
              onBlur={setFieldTouched}
              maxLength={50}
            />
            <FieldErrorMessage message={getFormErrorMessage('renavaVei')}/>
          </div>
        </>
      )}
      <div className="field col-12 md:col-3 field-multiplas-linhas">
        <RequiredLabel label="Chassi" valid={isFormFieldValid('chassiVei')}/>
        <TextInput
          placeholder="Chassi"
          name="chassiVei"
          value={form.values.chassiVei}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          className={classNames({ 'p-invalid': isFormFieldValid('chassiVei') }, 'inputfield w-full')}
          onBlur={setFieldTouched}
          maxLength={17}
        />
        <FieldErrorMessage message={getFormErrorMessage('chassiVei')}/>
      </div>
      <div className="field col-12 md:col-2 field-multiplas-linhas">
        <RequiredLabel label="KM Atual" valid={isFormFieldValid('kmatuVei')}/>
        <NumberInput
          placeholder="KM Atual"
          name="kmatuVei"
          value={form.values.kmatuVei}
          onChange={(e) => form.setFieldValue('kmatuVei', e.value, true)}
          disabled={!edicaoHabilitada}
          className={classNames({ 'p-invalid': isFormFieldValid('kmatuVei') }, 'inputfield w-full')}
          onBlur={setFieldTouched}
        />
        <FieldErrorMessage message={getFormErrorMessage('kmatuVei')}/>
      </div>
      <div className="field col-12 md:col-3 field-multiplas-linhas">
        <RequiredLabel label="Data/Hora medição" valid={isFormFieldValid('dahmedVei')}/>
        <DateTimeInput
          name="dahmedVei"
          placeholder="Data/Hora medição"
          value={form.values.dahmedVei}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          className={classNames({ 'p-invalid': isFormFieldValid('dahmedVei') }, 'inputfield w-full')}
          onBlur={setFieldTouched}
        />
        <FieldErrorMessage message={getFormErrorMessage('dahmedVei')}/>
      </div>
      <div className="field col-12 md:col-2 field-multiplas-linhas">
        <RequiredLabel label="Ano fabricação" valid={isFormFieldValid('anofabVei')}/>
        <NumberInput
          placeholder="Ano fabricação"
          name="anofabVei"
          value={form.values.anofabVei}
          onChange={(e) => form.setFieldValue('anofabVei', e.value, true)}
          disabled={!edicaoHabilitada}
          max={9999}
          className={classNames({ 'p-invalid': isFormFieldValid('anofabVei') }, 'inputfield w-full')}
          onBlur={setFieldTouched}
        />
        <FieldErrorMessage message={getFormErrorMessage('anofabVei')}/>
      </div>
      <div className="field col-12 md:col-2 field-multiplas-linhas">
        <RequiredLabel label="Ano modelo" valid={isFormFieldValid('anomodVei')}/>
        <NumberInput
          placeholder="Ano modelo"
          name="anomodVei"
          value={form.values.anomodVei}
          onChange={(e) => form.setFieldValue('anomodVei', e.value, true)}
          disabled={!edicaoHabilitada}
          max={9999}
          className={classNames({ 'p-invalid': isFormFieldValid('anomodVei') }, 'inputfield w-full')}
          onBlur={setFieldTouched}
        />
        <FieldErrorMessage message={getFormErrorMessage('anomodVei')}/>
      </div>
      {!isVeiculoNovo && (
        <>
          <div className="field col-12 md:col-3 field-multiplas-linhas">
            <RequiredLabel label="Número CRV" valid={isFormFieldValid('numcrvVei')}/>
            <TextInput
              placeholder="Número CRV"
              name="numcrvVei"
              value={form.values.numcrvVei}
              onChange={form.handleChange}
              disabled={!edicaoHabilitada}
              className={classNames({ 'p-invalid': isFormFieldValid('numcrvVei') }, 'inputfield w-full')}
              onBlur={setFieldTouched}
            />
            <FieldErrorMessage message={getFormErrorMessage('numcrvVei')}/>
          </div>
          <div className="field col-12 md:col-3 field-multiplas-linhas">
            <RequiredLabel label="Cód. segurança CRV" valid={isFormFieldValid('codsegcrvVei')}/>
            <TextInput
              placeholder="Cód. segurança CRV"
              name="codsegcrvVei"
              value={form.values.codsegcrvVei}
              onChange={form.handleChange}
              disabled={!edicaoHabilitada}
              className={classNames({ 'p-invalid': isFormFieldValid('codsegcrvVei') }, 'inputfield w-full')}
              onBlur={setFieldTouched}
            />
            <FieldErrorMessage message={getFormErrorMessage('codsegcrvVei')}/>
          </div>
          <div className="field col-12 md:col-2 field-multiplas-linhas">
            <RequiredLabel label="Tipo do CRV" valid={isFormFieldValid('tipcrvVei')}/>
            <SelectInput
              value={form.values.tipoCrv}
              options={tiposCrv}
              optionLabel="descri_tcr"
              placeholder="- Selecione -"
              name="tipcrvVei"
              onChange={(e) => {
                form.setFieldValue('tipoCrv', e.target.value)
                form.setFieldValue('tipcrvVei', e.target.value.codigo_tcr)
              }}
              disabled={!edicaoHabilitada}
              className={classNames({ 'p-invalid': isFormFieldValid('tipcrvVei') }, 'inputfield w-full')}
              onBlur={setFieldTouched}
              noFloatLabel
            />
            <FieldErrorMessage message={getFormErrorMessage('tipcrvVei')}/>
          </div>
        </>
      )}
      <div className="field col-12 md:col-10 field-multiplas-linhas">
        <RequiredLabel label="Descrição do veículo" valid={isFormFieldValid('descriVei')}/>
        <TextInput
          placeholder="Descrição do veículo"
          name="descriVei"
          value={form.values.descriVei}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          className={classNames({ 'p-invalid': isFormFieldValid('descriVei') }, 'inputfield w-full')}
          onBlur={setFieldTouched}
        />
        <FieldErrorMessage message={getFormErrorMessage('descriVei')}/>
      </div>
      <div className="field col-12 md:col-2">
        <RequiredLabel label="Cor do veículo" valid={isFormFieldValid('corVei')}/>
        <TextInput
          placeholder="Cor do veículo"
          name="corVei"
          value={form.values.corVei}
          onChange={form.handleChange}
          disabled={!edicaoHabilitada}
          className={classNames({ 'p-invalid': isFormFieldValid('corVei') }, 'inputfield w-full')}
          onBlur={setFieldTouched}
        />
        <FieldErrorMessage message={getFormErrorMessage('corVei')}/>
      </div>
    </div>
  )
}

export default DadosPrincipaisVei

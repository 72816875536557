import React, { useEffect, useState } from 'react'
import PanelContainer from '../../../../components/layout/PanelContainer'
import EstoqueRenaveService from '../../../../services/suporte/EstoqueRenaveService'
import DadosAutorizacaoTransferenciaEstabelecimento from './DadosAutorizacaoTransferenciaEstabelecimento'
import PessoaService from '../../../../services/cadastro/PessoaService'
import { showErrorMessage } from '../../../../components/utils/Message'

function AutorizarTransferenciaUsadosEstabelecimento (props) {
  const [veiculo, setVeiculo] = useState(null)
  const [valorVeiculo, setValorVeiculo] = useState(0)
  const [pessoaDestino, setPessoaDetino] = useState(null)

  useEffect(async () => {
    const queryPlaca = getQueryParam('placa')?.toUpperCase()
    if (!queryPlaca) {
      showErrorMessage('Informe o parâmetro na url: placa')
      return
    }
    const vei = await EstoqueRenaveService.getByPlaca(queryPlaca)
    if (!vei) {
      showErrorMessage(`Veículo ${queryPlaca} não encontrado!`)
    }
    setVeiculo(await EstoqueRenaveService.getByPlaca(queryPlaca))

    const queryCnpjEstabelecimento = getQueryParam('cnpj_transferencia')
    if (queryCnpjEstabelecimento) {
      fetchPessoa(queryCnpjEstabelecimento)
    }
    const queryCodigoEmpresa = getQueryParam('codigo_empresa')
    if (queryCodigoEmpresa) {
      fetchPessoa(null, queryCodigoEmpresa)
    }

    const queryValor = getQueryParam('valor')
    setValorVeiculo(queryValor)
  }, [])

  const fetchPessoa = async (cnpjEstabelecimento, queryCodigoEmpresa) => {
    try {
      const pessoaService = new PessoaService()
      let qtdResultados = 0
      if (cnpjEstabelecimento) {
        const pessoas = await pessoaService.filter(`apenasPessoasJuridicas=true&cpfCnpj=${cnpjEstabelecimento}`, 1, 100)
        if (pessoas?.data?.length === 1) {
          setPessoaDetino(pessoas.data[0])
          return
        }
        qtdResultados = pessoas?.data?.length
      }
      if (queryCodigoEmpresa) {
        const pessoa = await pessoaService.get(queryCodigoEmpresa)
        if (pessoa) {
          setPessoaDetino(pessoa)
          return
        }
      }
      // Se não parou antes, mostra a qtd para o usuário
      showErrorMessage(`Foram encontrados ${qtdResultados} resultados para a empresa ${cnpjEstabelecimento || queryCodigoEmpresa}`)
    } catch (error) {
      console.error('Erro ao buscar empresas:', error)
    }
  }

  const getQueryParam = (param) => {
    const params = new URLSearchParams(location.search)
    return params.get(param)
  }

  return (
    <>
      <h4 className="mt-3 mb-0">Autorizar transferência entre estabelecimentos</h4>
      <PanelContainer toggeable={false} header="AUTORIZAÇÃO DE TRANSFERÊNCIA">
        <DadosAutorizacaoTransferenciaEstabelecimento
          veiculo={veiculo}
          pessoaDestino={pessoaDestino}
          valorVeiculo={valorVeiculo}
        />
      </PanelContainer>
    </>
  )
}

export default AutorizarTransferenciaUsadosEstabelecimento

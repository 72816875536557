import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { classNames } from 'primereact/utils'
import React from 'react'
import List from '../../../classes/List'
import DropdownMenu from '../../../components/inputs/DropdownButton/DropdownMenu'
import Container from '../../../components/layout/Container'
import Page from '../../../components/layout/Page'
import DataList from '../../../components/utils/DataList'
import { showErrorMessage, showSuccessMessage, showWarnMessage } from '../../../components/utils/Message'
import ResponsiveColumn from '../../../components/utils/ResponsiveColumn'
import { getEstoqueIteDTO } from '../../../dtos/ite/EstoqueIteDTO'
import VeiculoModal from '../../../forms/cadastro/veiculo/VeiculoModal'
import CommonHelper from '../../../helpers/CommonHelper'
import { formatDescricaoVeiculo } from '../../../helpers/formaters'
import AtpvService from '../../../services/atpv/AtpvService'
import EstoqueIteService from '../../../services/ITE/EstoqueIteService'
import IteService from '../../../services/ITE/IteService'
import EntradaEstoqueIteModal from './EntradaEstoqueIteModal'
import SaidaEstoqueIteModal from './SaidaEstoqueIteModal'

class EstoqueITE extends List {
  constructor (props) {
    super(
      props,
      getEstoqueIteDTO,
      EstoqueIteService,
      '',
      '',
      'confirmadosESolicitados'
    )

    this.state = {
      ...this.state,
      visibleEntradaEstoqueIteModal: false,
      visibleSaidaEstoqueIteModal: false,
      visibleVeiculoModal: false
    }

    this.setColumns([
      {
        key: 'id',
        component: (
          <Column
            key='id'
            style={{ width: '10%' }}
            header="Id Estoque"
            field="id"
            sortable
            headerClassName="id"
          />
        )
      },
      {
        key: 'chassi',
        component: (
          <Column
            key= 'chassi'
            style={{ width: '30%' }}
            header="Veiculo"
            body={data => formatDescricaoVeiculo(data.chassi, data.descricaoMarcaModelo)}
            field="chassi"
            sortable
            headerClassName="chassi"
          />
        )
      },
      {
        key: 'veiculoAcabado',
        component: (
          <Column
            key= 'veiculoAcabado'
            style={{ width: '10%' }}
            header="Acabado"
            body={data => <ResponsiveColumn
              column="veiculoAcabado"
              value={data.veiculoAcabado === 0 ? 'Inacabado' : 'Acabado'}
              className={data.veiculoAcabado === 0 ? 'colored-label orange' : 'colored-label blue'}
            />}
            field="veiculoAcabado"
            sortable
            headerClassName="veiculoAcabado"
          />
        )
      },
      {
        key: 'entradaEstoque.dataHora',
        component: (
          <Column
            key= 'entradaEstoque.dataHora'
            style={{ width: '15%' }}
            header="Data Entrada"
            body={data => CommonHelper.dateToBrazilian(data.entradaEstoque.dataHora)}
            field="entradaEstoque.dataHora"
            sortable
            headerClassName="entradaEstoque.dataHora"
          />
        )
      },
      {
        key: 'saidaEstoque.dataHora',
        component: (
          <Column
            key= 'saidaEstoque.dataHora'
            style={{ width: '15%' }}
            header="Data Saída"
            body={data => CommonHelper.dateToBrazilian(data.saidaEstoque?.dataHora)}
            field="saidaEstoque.dataHora"
            sortable
            headerClassName="saidaEstoque.dataHora"
          />
        )
      },
      {
        key: 'restricoesVeiculo',
        component: (
          <Column
            key= 'restricoesVeiculo'
            style={{ width: '10%' }}
            body={data => data.restricoesVeiculo ? 'Sim' : 'Não'}
            header="Restrições"
            field="restricoesVeiculo"
            sortable
            headerClassName="restricoesVeiculo"
          />
        )
      },
      {
        key: 'estado',
        component: (
          <Column
            key= 'estado'
            style={{ width: '10%' }}
            header="Situação"
            body={data => {
              let descricaoSituacao = ''
              let descricaoColor = ''

              switch (data.estado) {
              case 'CONFIRMADO':
                descricaoSituacao = 'Estoque renave'
                descricaoColor = 'green'
                break
              case 'SOLICITADO':
                descricaoSituacao = 'Entrada solicitada'
                descricaoColor = 'light-green'
                break
              case 'FINALIZADO':
                descricaoSituacao = 'Processo concluído'
                descricaoColor = 'blue'
                break
              }

              return <ResponsiveColumn
                column="Situação"
                value={descricaoSituacao}
                className={`colored-label ${descricaoColor}`}
              />
            }}
            field="estado"
            sortable
            headerClassName="estado"
          />
        )
      }
    ])
  }

  async cancelarSaida (idEstoque) {
    try {
      await IteService.cancelarSaida(idEstoque)
      await this.onFilter()
      showSuccessMessage('Saída cancelada com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar cancelar a saída!')
    }
  }

  async cancelarEntrada (idEstoque) {
    try {
      await IteService.cancelarEntrada(idEstoque)
      await this.onFilter()
      showSuccessMessage('Entrada cancelada com sucesso!')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar cancelar a entrada!')
    }
  }

  async downloadTermoEntrada () {
    try {
      const { id, chassi } = this.state.selected

      const pdfTermoEntradaBase64 = await IteService.getTermoEntrada(id)

      CommonHelper.downloadFile('data:application/pdf;base64,', pdfTermoEntradaBase64, `Termo-Entrada-${chassi}.pdf`)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar fazer o download do termo de entrada!')
    }
  }

  async downloadATPVE () {
    try {
      const { chassi } = this.state.selected

      const pfdAtpvBase64 = await IteService.getATPVE(chassi)

      const pfdAtpvBase64Atualizado = await AtpvService.atualizarAtpv(pfdAtpvBase64)

      CommonHelper.downloadFile('data:application/pdf;base64,', pfdAtpvBase64Atualizado, `ATPV-${chassi}.pdf`)
    } catch (error) {
      showErrorMessage(error.message || 'Houve um erro ao baixar o ATPV-e')
    }
  }

  render () {
    const state = this.state

    const itensImpressao = [
      {
        label: 'Termo de entrada',
        command: () => this.downloadTermoEntrada()
      },
      {
        label: 'ATPV-e',
        command: () => this.downloadATPVE()
      }
    ]

    const rowExpansionTemplate = (data) => {
      return (
        <>
          <div className="expansion row-expansion flex flex-column justify-content-start align-items-start">
            <label>
            Veículo:
              <span className="black underline cursor-pointer ml-1" onClick={() => {
                if (!data.codigoVeiculo) {
                  showWarnMessage('Não foi possível encontrar o veículo nos cadastros, por favor cadastre o veículo!')
                  return
                }

                this.setState({ codigoVeiculo: data.codigoVeiculo, visibleVeiculoModal: true })
              }}>
                {data.chassi}
              </span>
            </label>
          </div>
          <div className="row-expansion flex justify-content-end mr-3">
            {/* <Button
              label="Acabar veiculo"
              className="mr-2"
              onClick={() => IteService.acabarVeiculo(data.chassi)}
            /> */}
            <div className="flex align-items-center">
              <DropdownMenu
                items={itensImpressao}
                className="mr-2 w-full"
                label="Impressões"
              />
            </div>
            {data.estado === 'FINALIZADO' && (
              <Button
                label="Cancelar saída"
                className="mr-2"
                onClick={async () => await this.cancelarSaida(data.id)}
              />
            )}
            {data.estado === 'CONFIRMADO' && (
              <Button
                label="Cancelar entrada"
                className="mr-2"
                onClick={async () => await this.cancelarEntrada(data.id)}
              />
            )}
            {data.estado === 'CONFIRMADO' && (
              <Button
                label="Saída de estoque"
                className="mr-2"
                onClick={() => this.setState({ visibleSaidaEstoqueIteModal: true })}
              />
            )}
          </div>
        </>
      )
    }

    const onSelectOption = (option) => {
      let { filter } = this.state
      this.setState({ opcaoSelecionada: option })

      switch (option) {
      case 'confirmadosESolicitados':
        filter.situacaoVeiculos = 'confirmadosESolicitados'
        break
      case 'andamento':
        filter.situacaoVeiculos = 'andamento'
        break
      case 'finalizados':
        filter.situacaoVeiculos = 'finalizados'
        break
      case 'todos':
        filter.situacaoVeiculos = 'todos'
        break
      }

      this.setState({ filter }, this.onFilter)
    }

    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Estoque de veículos ITE</h4>
        </div>
        <div className="table-options-container">
          <div className="table-options">
            <div className={classNames({ active: this.state.opcaoSelecionada === 'confirmadosESolicitados' }, 'filter-option')}
              onClick={() => onSelectOption('confirmadosESolicitados')}>
              <span className="option-label">Solicitados/Estoque Renave</span>
              <div className="option-quantity">
                {state.quantidades.quantidadeConfirmadosESolicitados}
              </div>
            </div>
            <div className={classNames({ active: this.state.opcaoSelecionada === 'andamento' }, 'filter-option')}
              onClick={() => onSelectOption('andamento')}>
              <span className="option-label">Em Andamento</span>
              <div className="option-quantity">
                {state.quantidades.quantidadeTransferenciaSaida}
              </div>
            </div>
            <div className={classNames({ active: this.state.opcaoSelecionada === 'finalizados' }, 'filter-option')}
              onClick={() => onSelectOption('finalizados')}>
              <span className="option-label">Finalizados</span>
              <div className="option-quantity">
                {state.quantidades.quantidadeFinalizados}
              </div>
            </div>
            <div className={classNames({ active: this.state.opcaoSelecionada === 'todos' }, 'filter-option')}
              onClick={() => onSelectOption('todos')}>
              <span className="option-label">Todos</span>
              <div className="option-quantity">
                {state.quantidades.quantidadeTodosOsRegistros}
              </div>
            </div>
          </div>
          <div className='flex'>
            <div>
              <Button
                label="Entrada de estoque"
                className="mr-2"
                onClick={() => this.setState({ visibleEntradaEstoqueIteModal: true })}
              />
            </div>
          </div>
        </div>
        <Container>
          <DataList
            data={state.list}
            selected={state.selected}
            rows={state.rows}
            onSelect={this.onSelect}
            totalRecords={state.totalRecords}
            first={state.first}
            onPage={this.onPageChange}
            page={state.page}
            responsive
            showFilter
            filterPlaceholder="Procurar estoque usados"
            filterName="descricaoFiltro"
            filterOnChange={this.handleChangeFilter}
            onFilter={this.onFilter}
            onRowClick={(e) => this.handleRowExpansion(e.data)}
            expandedRows={this.state.expandedRows}
            rowExpansionTemplate={rowExpansionTemplate}
            expandedFiltersTemplate={this.expandedFiltersTemplate}
          >
            {this.columns.map((column) => column.component)}
          </DataList>
        </Container>
        <EntradaEstoqueIteModal
          visible={state.visibleEntradaEstoqueIteModal}
          onHide={() => this.setState({ visibleEntradaEstoqueIteModal: false })}
          onFilter={this.onFilter}
        />
        <SaidaEstoqueIteModal
          visible={state.visibleSaidaEstoqueIteModal}
          onHide={() => this.setState({ visibleSaidaEstoqueIteModal: false })}
          onFilter={this.onFilter}
          veiculo={state.selected}
        />
        <VeiculoModal
          visible={state.visibleVeiculoModal}
          hide={() => this.setState({ visibleVeiculoModal: false })}
          isVeiculoNovo={true}
          primaryKey={state.codigoVeiculo}
          onRegister={async () => await this.onFilter()}
          edicaoHabilitada={true}
        />
      </Page>
    )
  }
}

export default EstoqueITE

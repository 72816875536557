import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import React from 'react'
import { IoWarningOutline } from 'react-icons/io5'
import List from '../../../../classes/List'
import DropdownMenu from '../../../../components/inputs/DropdownButton/DropdownMenu'
import Container from '../../../../components/layout/Container'
import Page from '../../../../components/layout/Page'
import DataList from '../../../../components/utils/DataList'
import { showErrorMessage, showSuccessMessage } from '../../../../components/utils/Message'
import ResponsiveColumn from '../../../../components/utils/ResponsiveColumn'
import { getSaidaZeroKmDTO } from '../../../../dtos/zeroKm/SaidaZeroKmDTO'
import { formatDate, formatDescricaoVeiculo, formatToCurrency } from '../../../../helpers/formaters'
import SaidaZeroKmService from '../../../../services/zeroKm/veiculos/SaidaZeroKmService'
import ConfirmacaoErroModal from '../ConfirmacaoErroModal'
import ConfirmacaoOptionsModal from '../ConfirmacaoOptionsModal'
import TrocarVeiculoModal from '../TrocarVeiculoModal'
import DevolverVeiculoModal from './DevolverVeiculoModal'
import Confirm from '../../../../components/utils/Confirm'

class ConfirmarSaidaZeroKm extends List {
  constructor (props) {
    super(
      props,
      getSaidaZeroKmDTO,
      SaidaZeroKmService
    )

    this.state = {
      ...this.state,
      selectedRows: [],
      rowsSelecionadasParaConfirmar: [],
      visibleDevolverVeiculoModal: false,
      visibleTrocarVeiculoModal: false,
      visibleOptionModal: false,
      visibleConfirmacaoErroModal: false,
      visibleConfirmSaidaLeasing: false,
      leasing: false,
      veiculoSelecionado: {},
      idEstoque: 0,
      interval: null,
      itensAcoes: [],
      itensAcoesLeasing: []
    }
  }

  async componentDidMount () {
    await this.getAll()
    this.addEventListeners()

    const interval = setInterval(() => {
      this.getAll()
    }, 300000)

    const itensAcoes = [
      {
        label: 'Devolver',
        command: (data) => {
          this.setState({ veiculoSelecionado: data, visibleDevolverVeiculoModal: true, idEstoque: data.id || data.idEstoque || data.idest_vei })
        }
      }
    ]

    const itensAcoesLeasing = [
      {
        label: 'Devolver',
        command: (data) => {
          this.setState({ veiculoSelecionado: data, visibleDevolverVeiculoModal: true, idEstoque: data.id || data.idEstoque || data.idest_vei })
        }
      },
      {
        label: 'Confirmar saída com leasing',
        command: (data) => {
          this.setState({ veiculoSelecionado: data, visibleConfirmSaidaLeasing: true, idEstoque: data.id || data.idEstoque || data.idest_vei })
        }
      }
    ]

    this.setState({ interval, itensAcoes, itensAcoesLeasing })
  }

  componentWillUnmount () {
    clearInterval(this.state.interval)
  }

  rowClass = (data) => {
    return {
      'row-sem-xml-saida': !data.numeronf,
      'row-processando': data.status_processandoapi
    }
  }

  handleConfirmarSaida = async () => {
    if (this.state.rowsSelecionadasParaConfirmar.length > 0) {
      try {
        await SaidaZeroKmService.confirmarSaida(this.state.rowsSelecionadasParaConfirmar)
        await this.getAll()
        this.setState({ rowsSelecionadasParaConfirmar: [], selectedRows: [] })
        showSuccessMessage('A sua solicitação de confirmação de saída foi registrada com sucesso!')
      } catch (error) {
        showErrorMessage(error.message || 'Houve um erro ao confirmar a(s) saída(s)')
      }
    }
  }

  handleSelectionChange = (e) => {
    const quantidadeSelecionaveis = this.state.list.filter(data => data.numeronf && !data.status_processandoapi).length
    let selected = e.value.filter(data => data.numeronf && !data.status_processandoapi)

    if (selected.length === this.state.selectedRows.length) {
      selected = []
    }

    if (selected.length === 0) {
      e.value = []
    }

    if (selected.length === quantidadeSelecionaveis) {
      e.value = this.state.list
    }

    this.setState({ selectedRows: e.value, rowsSelecionadasParaConfirmar: selected })
  }

  handleConfirmarSaidaLeasing = async (event) => {
    this.state.visibleConfirmSaidaLeasing = false
    this.state.veiculoSelecionado.leasing = true
    this.state.rowsSelecionadasParaConfirmar = [this.state.veiculoSelecionado]

    this.handleConfirmarSaida()
  }

  render () {
    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Confirmação de saída</h4>
          <div className="page-header-buttons">
            <Button
              icon="pi pi-refresh"
              onClick={() => this.onFilter()}
              className="mr-5"
            />
          </div>
        </div>
        <Container>
          <DataList
            className="selection-datalist"
            data={this.state.list}
            selected={this.state.selectedRows}
            onSelectionChange={e => this.handleSelectionChange(e)}
            dataKey="chassi"
            responsiveLayout="scroll"
            responsive={true}
            rowClassName={this.rowClass}
            selectionMode="checkbox"
          >
            <Column
              style={{ width: '5%' }}
              selectionMode="multiple"
            />
            <Column
              header="NF"
              field="numeronf"
              style={{ width: '8%', maxWidth: '8%' }}
              sortable
              headerClassName="numeronf"
            />
            <Column
              header="Série"
              field="serie"
              style={{ width: '5%', maxWidth: '5%' }}
              sortable
              headerClassName="serie"
            />
            <Column
              header="Veículo"
              field="chassi"
              body={data => {
                const marcaModelo = data.marcamodelo || data.marcaModelo

                return formatDescricaoVeiculo(data.chassi, marcaModelo)
              }}
              style={{ width: '25%', maxWidth: '25%' }}
              sortable
              headerClassName="chassi"
            />
            <Column
              header="Inacabado?"
              field="acabado_vei"
              body={data => <ResponsiveColumn
                column="acabado_vei"
                value={data.acabado_vei === 0 || data.inacabado ? 'Inacabado' : 'Acabado'}
                className={data.acabado_vei === 0 || data.inacabado ? 'colored-label orange' : 'colored-label blue'}
              />}
              style={{ width: '7%', maxWidth: '7%' }}
              sortable
              headerClassName="acabado_vei"
            />
            <Column
              header="nome"
              field="nome"
              body={data => <ResponsiveColumn
                column="nome"
                value={data.nome ? data.nome : 'Estoque renave'}
                className={!data.nome ? 'colored-label green' : ''}
              />}
              style={{ width: '10%', maxWidth: '10%' }}
              sortable
              headerClassName="nome"
            />
            <Column
              header="Data Emissão"
              field="dataemissao"
              body={data => data.dataemissao ? formatDate(data.dataemissao) : ''}
              style={{ width: '10%', maxWidth: '10%' }}
              sortable
              headerClassName="dataemissao"
            />
            <Column
              header="Valor Total"
              field="valorTotal"
              body={data => data.valorentrada ? formatToCurrency(data.valorentrada) : ''}
              style={{ width: '10%', maxWidth: '10%' }}
              sortable
              headerClassName="valorTotal"
            />
            <Column
              header="Info"
              field="info"
              body={data => {
                if (data.status_processandoapi) {
                  return (
                    <i className="pi pi-spin pi-spinner info-selection"/>
                  )
                }

                if ((data.outrasNotas && data.outrasNotas.length > 0) && (data.erromensagem || data.errodetalhe)) {
                  return (
                    <IoWarningOutline
                      className="info-selection"
                      onClick={() => this.setState({ veiculoSelecionado: data, visibleOptionModal: true })}
                    />
                  )
                }

                if (data.outrasNotas && data.outrasNotas.length > 0) {
                  return (
                    <i
                      className="pi pi-info-circle info-selection"
                      onClick={() => this.setState({ veiculoSelecionado: data, visibleTrocarVeiculoModal: true })}
                    />
                  )
                }

                if (data.erromensagem || data.errodetalhe) {
                  return (
                    <IoWarningOutline
                      className="info-selection"
                      onClick={() => this.setState({ veiculoSelecionado: data, visibleConfirmacaoErroModal: true })}
                    />
                  )
                }
              }}
              style={{ width: '7%', maxWidth: '7%' }}
            />
            <Column
              header="Ações"
              body={(data) => {
                return <DropdownMenu
                  items= {!data.acabado_vei && data.codigo ? this.state.itensAcoesLeasing : this.state.itensAcoes}
                  icon="pi pi-ellipsis-v"
                  data={data}
                  onlyIcon
                />
              }}
              style={{ width: '10%', maxWidth: '13%' }}
            />
          </DataList>
          <div className="table-footer">
            <div className="grid col-12 justify-content-end">
              <Button
                label="Confirmar saídas selecionadas"
                className="confirm-button"
                onClick={() => this.handleConfirmarSaida()}
              />
            </div>
          </div>
        </Container>
        <ConfirmacaoErroModal
          visible={this.state.visibleConfirmacaoErroModal}
          onHide={() => this.setState({ visibleConfirmacaoErroModal: false })}
          mensagem={this.state.veiculoSelecionado.erromensagem || this.state.veiculoSelecionado.errodetalhe}
        />
        <ConfirmacaoOptionsModal
          visible={this.state.visibleOptionModal}
          onHide={() => this.setState({ visibleOptionModal: false })}
          openTrocarNotaModal={() => this.setState({ visibleTrocarVeiculoModal: true })}
          openConfirmacaoErroModal={() => this.setState({ visibleConfirmacaoErroModal: true })}
        />
        <TrocarVeiculoModal
          visible={this.state.visibleTrocarVeiculoModal}
          onHide={() => this.setState({ visibleTrocarVeiculoModal: false })}
          veiculo={this.state.veiculoSelecionado}
          getAll={this.getAll}
        />
        <DevolverVeiculoModal
          visible={this.state.visibleDevolverVeiculoModal}
          chassi={this.state.veiculoSelecionado.chassi}
          idEstoque={this.state.idEstoque}
          idEstoqueOrigemTransferencia={this.state.veiculoSelecionado.idEstoqueOrigemTransferencia}
          getAll={this.getAll}
          onHide={() => this.setState({ visibleDevolverVeiculoModal: false, idEstoque: 0 })}
        />
        <Confirm
          visible={this.state.visibleConfirmSaidaLeasing}
          onConfirm={() => this.handleConfirmarSaidaLeasing()}
          onCancel={() => this.setState({ visibleConfirmSaidaLeasing: false })}
          title="Confirmação"
          description="Confirma saída de veículo inacabado com indicação de leasing?"
          cancelLabel="Não"
        />
      </Page>
    )
  }
}

export default ConfirmarSaidaZeroKm

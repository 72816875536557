import React from 'react'
import DateInput from '../../../components/inputs/DateInput'
import TextInput from '../../../components/inputs/TextInput'

const DadosAdicionaisVei = ({ form }) => {
  return (
    <div className="formgrid grid">
      <div className="field col-12 md:col-3">
        <TextInput
          label="Situação do veículo"
          placeholder="Situação do veículo"
          value={form.values.descriSit}
          disabled={true}
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 md:col-9">
        <TextInput
          label="Proprietário"
          placeholder="Proprietário"
          value={form.values.nomrazPes}
          disabled={true}
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 md:col-3">
        <DateInput
          label="Data de entrada"
          name="datentVei"
          placeholder="Data de entrada"
          value={form.values.datentVei}
          disabled={true}
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 md:col-3">
        <DateInput
          label="Data de saída"
          name="datsaiVei"
          placeholder="Data de saída"
          value={form.values.datsaiVei}
          disabled={true}
          className="inputfield w-full"
        />
      </div>
      <div className="field col-12 md:col-3">
        <TextInput
          label="Id estoque Renave"
          name="idEstoque"
          placeholder="Id estoque Renave"
          value={form.values.idestVei}
          disabled={true}
          className="inputfield w-full"
        />
      </div>
    </div>
  )
}

export default DadosAdicionaisVei

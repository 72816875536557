import ServiceBase from '../base/ServiceBase'

export default class ListagemECRVSPService {
  async getAll (page, perPage) {
    return await ServiceBase.getBase(`empresa/listarDadosVinculacaoECRV?page=${page}&perPage=${perPage}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`empresa/listarDadosVinculacaoECRV?${query}&page=${page}&perPage=${perPage}`)
  }

  static async definirVinculoECRV (vinculoECRV, codigo_emp) {
    return await ServiceBase.postBase('empresa/definirVinculoECRV', { vinculoECRV, codigo_emp })
  }
}

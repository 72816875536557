import React, { useEffect, useState } from 'react'
import PanelContainer from '../../../../components/layout/PanelContainer'
import EstoqueRenaveService from '../../../../services/suporte/EstoqueRenaveService'
import SolicitacaoTransferenciaFilial from './SolicitacaoTransferenciaFilial'
import { showErrorMessage } from '../../../../components/utils/Message'
import EmpresaService from '../../../../services/cadastro/EmpresaService'

function TransferenciaUsadoFilial (props) {
  const [veiculo, setVeiculo] = useState(null)
  const [filial, setFilial] = useState(null)
  const [kmVeiculo, setKmVeiculo] = useState(0)

  useEffect(async () => {
    const queryPlaca = getQueryParam('placa')?.toUpperCase()
    if (!queryPlaca) {
      showErrorMessage('Informe o parâmetro na url: placa')
      return
    }
    const vei = await EstoqueRenaveService.getByPlaca(queryPlaca)
    if (!vei) {
      showErrorMessage(`Veículo ${queryPlaca} não encontrado!`)
    }
    setVeiculo(vei)

    const queryCnpjFilial = getQueryParam('cnpj_filial')
    if (queryCnpjFilial) {
      fetchFilial(queryCnpjFilial)
    }
    const queryCodigoFilial = getQueryParam('codigo_filial')
    if (queryCodigoFilial) {
      fetchFilial(null, queryCodigoFilial)
    }

    const queryKmVei = getQueryParam('km')
    setKmVeiculo(queryKmVei)
  }, [])

  const fetchFilial = async (cnpjFilial, codigoFilial) => {
    try {
      const empresaService = new EmpresaService()
      let qtdResultados = 0
      if (cnpjFilial) {
        const filiais = await empresaService.filter(`apenasFiliais=true&descricaoFiltro=${cnpjFilial}`)
        if (filiais?.data?.length === 1) {
          setFilial(filiais.data[0])
          return
        }
        qtdResultados = filiais?.data?.length
      }
      if (codigoFilial) {
        const filialPes = await empresaService.get(codigoFilial)
        if (filialPes) {
          setFilial(filialPes)
          return
        }
      }
      // Se não parou antes, mostra a qtd para o usuário
      showErrorMessage(`Foram encontrados ${qtdResultados} resultados para a filial ${cnpjFilial || codigoFilial}`)
    } catch (error) {
      console.error('Erro ao buscar filiais:', error)
    }
  }

  const getQueryParam = (param) => {
    const params = new URLSearchParams(location.search)
    return params.get(param)
  }

  return (
    <>
      <h4 className="mt-3 mb-0">Transferência entre filiais</h4>
      <PanelContainer toggeable={false} header="SOLICITAÇÃO DE TRANSFERÊNCIA">
        <SolicitacaoTransferenciaFilial
          veiculo={veiculo}
          filialProp={filial}
          kmVeiculo={kmVeiculo}
        />
      </PanelContainer>
    </>
  )
}

export default TransferenciaUsadoFilial

import React from 'react'
import { Column } from 'primereact/column'
import classNames from 'classnames'
import List from '../../classes/List'
import ConviteLojistaService from '../../services/despachante/ConviteLojistaService'
import { formatDateTime } from '../../helpers/formaters'
import DataList from '../../components/utils/DataList'
import Container from '../../components/layout/Container'
import Page from '../../components/layout/Page'
import { Button } from 'primereact/button'
import ConvidarLojistaModal from '../historico/ConvidarLojistaModal'
import Confirm from '../../components/utils/Confirm'
import DespachanteService from '../../services/despachante/DespachanteService'
import { showErrorMessage, showSuccessMessage } from '../../components/utils/Message'
import { getStore } from '../../redux/store'

export default class ConviteLojistaList extends List {
  constructor (props) {
    super(props, {}, ConviteLojistaService)

    this.state = {
      ...this.state,
      visibleConvidarLojistaModal: false,
      visibleConfirm: false,
      codigoConvite: null,
      emailLojista: ''
    }

    this.setColumns([
      {
        key: 'nomloj_cvt',
        component: (
          <Column
            key="nomloj_cvt"
            field="nomloj_cvt"
            header="Nome lojista"
            sortable
            style={{ width: '15%', maxWidth: '15%' }}
            headerClassName="nomloj_cvt"
          />
        )
      },
      {
        key: 'email_cvt',
        component: (
          <Column
            key="email_cvt"
            field="email_cvt"
            header="Email lojista"
            sortable
            style={{ width: '15%', maxWidth: '15%' }}
            headerClassName="email_cvt"
          />
        )
      },
      {
        key: 'dahenv_cvt',
        component: (
          <Column
            key="dahenv_cvt"
            field="dahenv_cvt"
            header="Data/Hora último envio"
            body={data => formatDateTime(data.dahenv_cvt)}
            sortable
            style={{ width: '15%', maxWidth: '15%' }}
            headerClassName="dahenv_cvt"
          />
        )
      },
      {
        key: 'dahcad_cvt',
        component: (
          <Column
            key="dahcad_cvt"
            field="dahcad_cvt"
            header="Data/Hora lojista aceitou"
            body={data => data.dahcad_cvt ? formatDateTime(data.dahcad_cvt) : ''}
            sortable
            style={{ width: '15%', maxWidth: '15%' }}
            headerClassName="dahcad_cvt"
          />
        )
      },
      {
        key: 'nomfan_emp',
        component: (
          <Column
            key="nomfan_emp"
            field="nomfan_emp"
            header="Nome empresa lojista que aceitou"
            sortable
            style={{ width: '15%', maxWidth: '15%' }}
            headerClassName="nomfan_emp"
          />
        )
      },
      {
        key: 'reenviar',
        component: (
          <Column
            key="reenviar"
            field="reenviar"
            header="Re-enviar"
            body={data => (
              <Button
                icon="pi pi-refresh"
                onClick={() => this.setState({ visibleConfirm: true, codigoConvite: data.codigo_cvt, emailLojista: data.email_cvt })}
              />
            )}
            style={{ width: '15%', maxWidth: '15%' }}
          />
        )
      }
    ])
  }

  async reenviarConviteLojista () {
    try {
      if (!getStore().usuarioLogado.cpf_usu) {
        showWarnMessage('Por favor informe o CPF do usuário, editando o registro do usuario logado na tela de usuários!')
        return
      }

      await DespachanteService.reconvidarLojista(this.state.codigoConvite)

      this.setState({ visibleConfirm: false })

      showSuccessMessage('Convite enviado com sucesso!')

      await this.onFilter()
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar enviar o convite para o lojista!')
    }
  }

  render () {
    const state = this.state

    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Convites</h4>
          <div className="page-header-buttons">
            <Button
              label="Convidar lojista"
              onClick={() => this.setState({ visibleConvidarLojistaModal: true })}
            />
          </div>
        </div>
        <div className="table-options-container">
          <div className="table-options">
            <div className={classNames({ active: state.opcaoSelecionada === 't' }, 'filter-option')}>
              <span className="option-label">Todos</span>
              <div className="option-quantity">
                {state.totalRecords}
              </div>
            </div>
          </div>
        </div>
        <Container>
          <DataList
            data={state.list}
            selected={state.selected}
            rows={state.rows}
            onSelect={this.onSelect}
            totalRecords={state.totalRecords}
            first={state.first}
            onPage={this.onPageChange}
            page={state.page}
            showFilter={true}
            filterPlaceholder="Procurar pessoas"
            filterName="descricaoFiltro"
            responsive={true}
            filterOnChange={this.handleChangeFilter}
            onFilter={this.onFilter}
          >
            {this.columns.map(column => column.component)}
          </DataList>
          <ConvidarLojistaModal
            visible={state.visibleConvidarLojistaModal}
            onHide={() => this.setState({ visibleConvidarLojistaModal: false })}
            onFilter={this.onFilter}
          />
          <Confirm
            title="Confirma re-enviar o convite?"
            description={'Tem certeza que deseja re-enviar o convite para o email: ' + state.emailLojista}
            visible={state.visibleConfirm}
            onCancel={() => this.setState({ visibleConfirm: false })}
            onConfirm={async () => await this.reenviarConviteLojista()}
          />
        </Container>
      </Page>
    )
  }
}

import React, { useEffect, useState } from 'react'
import PanelContainer from '../../../../components/layout/PanelContainer'
import EstoqueRenaveService from '../../../../services/suporte/EstoqueRenaveService'
import SolicitacaoTransferenciaCliente from './SolicitacaoTransferenciaCliente'
import { showErrorMessage } from '../../../../components/utils/Message'
import PessoaService from '../../../../services/cadastro/PessoaService'

function TransferenciaUsadoCliente (props) {
  const [veiculo, setVeiculo] = useState(null)
  const [comprador, setComprador] = useState(null)
  const [valorVenda, setValorVenda] = useState(0)

  const getQueryParam = (param) => {
    const params = new URLSearchParams(location.search)
    return params.get(param)
  }

  useEffect(async () => {
    const queryPlaca = getQueryParam('placa')?.toUpperCase()

    if (!queryPlaca) {
      showErrorMessage('Informe o parâmetro na url: placa')
      return
    }
    const vei = await EstoqueRenaveService.getByPlaca(queryPlaca)

    if (!vei) {
      showErrorMessage(`Veículo ${queryPlaca} não encontrado!`)
    }
    setVeiculo(vei)

    const queryCpfCnpjComprador = getQueryParam('cpfcnpj_comprador')
    if (queryCpfCnpjComprador) {
      fetchComprador(queryCpfCnpjComprador)
    }
    const queryCodigoComprador = getQueryParam('codigo_comprador')
    if (queryCodigoComprador) {
      fetchComprador(null, queryCodigoComprador)
    }

    const queryValor = getQueryParam('valor')
    setValorVenda(queryValor)
  }, [])

  const fetchComprador = async (cpfCnpjComprador, codigoComprador) => {
    try {
      const pessoaService = new PessoaService()
      let qtdResultados = 0
      if (cpfCnpjComprador) {
        const compradores = await pessoaService.filter(`cpfCnpj=${cpfCnpjComprador}`, 1, 100)
        if (compradores?.data?.length === 1) {
          setComprador(compradores.data[0])
          return
        }
        qtdResultados = compradores?.data?.length
      }
      if (codigoComprador) {
        const compradorPes = await pessoaService.get(codigoComprador)
        if (compradorPes) {
          setComprador(compradorPes)
          return
        }
      }
      // Se não parou antes, mostra a qtd para o usuário
      showErrorMessage(`Foram encontrados ${qtdResultados} resultados para o comprador ${cpfCnpjComprador || codigoComprador}`)
    } catch (error) {
      console.error('Erro ao buscar compradores:', error)
    }
  }

  return (
    <>
      <h4 className="mt-3 mb-0">Transferência para cliente (Saída de estoque)</h4>
      <PanelContainer toggeable={false} header="SOLICITAÇÃO DE TRANSFERÊNCIA">
        <SolicitacaoTransferenciaCliente
          veiculo={veiculo}
          comprador={comprador}
          valorDaVenda={valorVenda}
        />
      </PanelContainer>
    </>
  )
}

export default TransferenciaUsadoCliente
